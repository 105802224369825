const messages = {
	en: {
		translations: {
			"pt_diferenca_td": "Previous Difference Points",
			"msg_saque_dia_td": "Due to the worldwide success and the constant volume of transactions in the system, withdrawal frequency is daily.",
			"sem_virgulas": "Do not use commas for decimals. Use points. Ex.: 99.50",
			"pagante_td": "Paying",
			"lider_lib": "Released Leader",
			"lider_bloq": "Blocked Leader",
			"voucher_td": "Voucher",
			"auth_td": "Two-Factor Authentication",
			"auth_ok": "Your account is protected with 2-step verification",
			"auth_ok_sub": "Whenever you log in to your Account, you will need to use your password and a unique verification code that was generated by the Google Authenticator App on your Smartphone.",
			"no_auth_td": "Disable Two-Factor Authentication",
			"proteja_2fa": "Protect your account with 2-step verification",
			"proteja_2fa_sub": "Whenever you log in to your Account, you will need to use your password and a verification code.",
			"addcamada_td": "Add an extra layer of security",
			"addcamada_sub": "Enter your password and the unique verification code that was generated by the Google Authenticator App on your Smartphone.",
			"keep_away_td": "Keep the bad guys away",
			"keep_away_sub": "Even if someone gets your password, it will not be enough to log into your account.",
			"do_auth_td": "Enable Two-Factor Authentication",
			"siga_passos_td": "Follow these steps to enable Two-Factor Authentication",
			"step_1": "Open the Google Authenticator App on your smartphone.",
			"step_2": "Click the add button and then click Read QR code.",
			"step_3": "Point your smartphone's camera at the code below:",
			"dig_td": "Enter the verification code",
			"code_verific_td": "Verification code",
			"code_verific_sub": "Enter the code that is appearing on your smartphone. If time runs out, just enter the new code that appeared.",
			"doverify_td": "Check",
			"invalid_td": "Invalid verification code",
			"load_qr": "Loading QR Code ...",
			"dig_login_td": "Enter the verification code generated in the Google Authenticator app",
			"materiais_td": "Support Materials",
			"msg_sucesso": "Your invoice was successfully generated and was forwarded to your registration email",
			"msg_renovacao": "You've reached your win limit, renew your package.",
			"msg_btc": "Invalid Wallet",
			"info_endereco": "Fill in all the fields below",
			"plano_titulo": "Career path",
			"msg_carreira": "Your new career plan has been updated",
			"completo_percent": "Complete",
			"pacotes_tb": "Package List",
			"view_qtd": "Amount",
			"msg_pacote": "Package chosen successfully",
			"msg_pacote_erro": "Could not finish",
			"msg_pacote_vazio": "You need to choose a package",
			"escolha_pacotes": "Choose a Package",
			"usuario_td": "user",
			"erro_logar": "Error Log",
			"fk_matriz": "Matrix",
			"erro_logar_msg": "Invalid username or password",
			"senha_td": "password",
			"esqueci_td": "forgot password",
			"acesso_td": "Access",
			"de_td": "",
			"seg_td": "security",
			"confirmar_td": "Confirm",
			"email_td": "Email",
			"enviar_td": "Send",
			"reenviar_td": "Resend",
			"visualizar_td": "Show",
			"dias_td": "Days",
			"horas_td": "Hours",
			"minutos_td": "Minutes",
			"pais_td": "Country",
			"paises_td": "Countries",
			"estado_td": "State",
			"cidade_td": "City",
			"banco_de_dados": "Database",
			"meus_contatos": "My Contacts",
			"banco_empresa": "Company Database",
			"escolher_td": "Choose",
			"todos_td": "All",
			"segunda_td": "Monday",
			"terca_td": "Tuesday",
			"quarta_td": "Wednesday",
			"quinta_td": "Thursday",
			"sexta_td": "Friday",
			"sabado_td": "Saturday",
			"domingo_td": "Sunday",
			"seg_sex": "Monday through Friday",
			"sab_dom": "Saturday and Sunday",
			"manha_td": "Morning",
			"tarde_td": "Late",
			"noite_td": "Night",
			"ativar_conta": "Enter your email account and confirm your registration",
			"ativar_zap": "We sent a message to the WHATSAPP:",
			"favor_zap": "Validate your WhatsApp by clicking on the activation link in the message we sent to you.",
			"sim_td": "Yes",
			"nao_td": "no",
			"parabens_td": "Congratulations",
			"sucesso_td": "Success",
			"erro_td": "error",
			"obrigado_td": "thank you,",
			"cadastrar_td": "Register",
			"gravar_td": "Record",
			"salvar_td": "Save",
			"agora_td": "Now",
			"postador_td": "Posting",
			"automatico_td": "Automatic",
			"texto_td": "Text",
			"imagem_td": "Image",
			"ddi_pais": "Country code",
			"cancelar_td": "Cancel",
			"continuar_td": "Continue",
			"parar_td": "Stop",
			"zap_td": "WhatsApp",
			"celular_td": "mobile",
			"telefone_td": "Phone",
			"nome_td": "name",
			"endereco_td": "address",
			"logradouro_td": "street address",
			"numero_td": "Number",
			"numeros_td": "Numbers",
			"bairro_td": "Neighborhood",
			"comp_td": "Add-On",
			"uf_td": "UF",
			"cep_td": "ZIP",
			"entrega_td": "Delivery",
			"data_td": "Date",
			"nasc_td": "Birth",
			"plano_td": "Plan",
			"bem_vindo": "Welcome",
			"efetuado_td": "Done with",
			"status_td": "Status",
			"upgrade_td": "Upgrade",
			"financeiro_td": "Finance",
			"principal_td": "Home",
			"banco_td": "Banks",
			"bancarios_td": "Banking",
			"rede_td": "Network",
			"binario_td": "Binary",
			"direto_td": "Direct",
			"linear_td": "Linear",
			"matriz_td": "Array",
			"trans_td": "transfer",
			"saldo_td": "Balance",
			"pg_saldo": "Pay with balance",
			"liberado_td": "Released",
			"bloqueado_td": "locked",
			"pendente_td": "Pending",
			"dados_td": "Data",
			"pessoais_td": "Personal",
			"nivel_td": "Level",
			"buscar_td": "Get",
			"moeda_td": "Currency",
			"dinheiro_td": "Money",
			"carreira_td": "Career",
			"subir_td": "up",
			"foto_td": "photo",
			"msg_td": "Message",
			"aqui_td": "Here",
			"extrato_td": "Extract",
			"sair_td": "Exit",
			"logar_td": "Login",
			"loja_td": "Store",
			"alterar_td": "replace",
			"perna_td": "Leg",
			"direita_td": "right",
			"esquerda_td": "left",
			"pedido_td": "order",
			"btc_td": "Bitcoin",
			"sacar_td": "Withdrawal",
			"taxa_td": "Rate",
			"estorno_td": "Chargeback",
			"link_de": "Registration Link",
			"patrocinador_td": "sponsor",
			"qualif_td": "Qualified",
			"qualificacao_td": "Qualification",
			"renovar_td": "Renew",
			"ativo_td": "Active",
			"inativo_td": "Inactive",
			"comprar_td": "Buy",
			"comprar_prod": "Buy Products",
			"titulo_msg": "Attention",
			"erro_msg": "Unable to generate request",
			"titulo_sucesso": "Congratulations",
			"sucesso_msg": "Request generated successfully",
			"pagar_td": "Pay",
			"realmente_td": "really want",
			"valor_exato": "Pay the above value exactly equal",
			"com_td": "with",
			"do_td": "",
			"virgula_td": ",",
			"apagar_td": "Delete",
			"limite_td": "Limit",
			"entrada_td": "entry",
			"saida_td": "Output",
			"total_td": "Total",
			"sub_total": "sub-total",
			"participe_td": "Join",
			"cota_td": "Quota",
			"divisao_lucro": "profit Division",
			"configura_td": "settings",
			"aviso_td": "Warning",
			"carrinho_td": "cart",
			"vazio_td": "This field cannot be empty",
			"abrir_td": "Open",
			"fechar_td": "Close",
			"cpf_td": "Document",
			"menu_td": "Menu",
			"valor_td": "Value",
			"pref_td": "Preferred",
			"auto_td": "Smaller",
			"escolha_td": "Choice",
			"disponivel_td": "Available",
			"pagamento_td": "Payment",
			"boleto_td": "Billet",
			"opcao_td": "Options",
			"pago_td": "Paid",
			"desc_td": "Description",
			"cred_td": "Credit",
			"deb_td": "Debit",
			"tipo_td": "Type",
			"privado_td": "Private",
			"grupo_td": "Group",
			"tipo_conta": "Account Type",
			"agencia_td": "Agency",
			"conta_td": "Account",
			"dv_td": "Digit",
			"favorecido_td": "Favored",
			"corrente_td": "Current",
			"poupanca_td": "Savings",
			"update_td": "Update",
			"escreva_td": "Write",
			"reescreva_td": "Rewrite",
			"ate_td": "Until",
			"nova_td": "New",
			"atual_td": "Current",
			"antiga_td": "Old",
			"carteira_td": "Wallet",
			"pix_td": "PIX key",
			"saque_td": "Serve",
			"brinde_td": "Toast",
			"ativar_td": "Activate",
			"upline_td": "Upline",
			"frete_td": "Freight",
			"passar_prazo_td": "If you pass the deadline you must generate",
			"potencial_td": "Potential Gains",
			"ficticio_vl": "Fictitious Values",
			"ja_ganhei": "I already won on",
			"campanha_td": "Campaign",
			"adicionar_td": "Add",
			"criar_td": "Create",
			"base_empresa": "Company List",
			"minha_base": "My list",
			"ferramentas_td": "Tools",
			"licencas_td": "Licenses",
			"serial_td": "Key",
			"videos_td": "Video lesson",
			"arquivo_td": "File",
			"importar_td": "Import",
			"exportar_td": "Export",
			"bonus_td": "Bonus",
			"investidor_td": "Investor",
			"simples_td": "Simple",
			"novamente_td": "Again",
			"robo_td": "Robot",
			"segmentado_td": "Segmented",
			"text_numeros": "Numbers or Link of groups separated by ; Example: xxxxxx;xxxxxx;xxxxxx",
			"envia_100": "Sends 100 contacts from",
			"msg_moderacao": "Attention! Use the robot in moderation if you are afraid do not use your personal chip.",
			"modo_td": "Mode",
			"msg_cad": "To continue the registration choose a paconte!",
			"emocao_td": "Cada hora una nueva emoción!",
			"pack": "My Pack",
			"data_ativa": "Activation Date",
			"qualif_binario": "Qualification in Binary",
			"plano_al": "Career path",
			"qtd_rede": "Network Quantity",
			"sys_tb": "In the system",
			"saque_td2": "Withdraw",
			"saque_td3": "Successfully",
			"ate_hj": "Until Today",
			"entrada_et": "Input",
			"tb_usuarios": "Users",
			"tb_usando_sys": "In the system",
			"plano_main": "Change Plan",
			"plano_main2": "I want to change my plan",
			"escolha_plano": "Choose a new plan below",
			"package_plano": "Package",
			"escolher_plano": "Choose",
			"total_e": "Total Left",
			"total_d": "Total right",
			"usu_rede": "User Login Below Your Network",
			"voltar_top": "Back to Top",
			"sub_nivel": "Up a Level",
			"list_p": "List of Points",
			"ped_b": "Order",
			"ped_bs": "Requests",
			"usu_b": "User",
			"data_b": "Date",
			"pts_b": "Points",
			"log_usu": "Enter user login",
			"total_ee": "Left",
			"total_dd": "Right",
			"limit_b": "Limit",
			"senha_up": "Change Password",
			"senha_f": "Change Financial Password",
			"taxa": "Fee",
			"comprovante_msg": "Submit Proof",
			"selecione_tb": "Select a photo",
			"chamado": "Open a call",
			"list_chamado": "Call List",
			"n_chamado": "Nº",
			"res_data": "answer",
			"ass_chamado": "Subject matter",
			"respondido": "Answered",
			"aberto": "Open",
			"depart": "Choose a Department",
			"finan": "financial",
			"support": "Support",
			"succ_call": "Call opened successfully",
			"err_call": "Could not open a ticket",
			"succ_chamado": "Your call was sent successfully",
			"err_chamado": "Unable to send your message",
			"atencao_td": "Attention",
			"senha_valid": "Invalid Password, verify your details and try again!",
			"success_senhaf": "Password confirmed successfully!",
			"carteira_td_succss": "Wallet successfully registered, check your e-mail to activate!",
			"pix_td_succss": "Pix Reminded Successfully, Check Your Email To Activate!",
			"saque_email_td": "Withdrawal requested, check your e-mail and click on the link to authorize!",
			"msg_wallet_email": "For your best safety, data for payment of this order ",
			"msg_wallet_email2": "have been sent to email ",
			"msg_wallet_email3": "Please check out the order number and the deadline for payment reported in the email.",
			"carteira_td_erro": "Unable to register your career, please verify your details and try again!",
			"cadastre_carteira": "Register a BTC Wallet or a pix key to make withdrawals",
			"msg_btc_vazio": "Fill in the Bitcoin field",
			"sacar_api_td": "Pay API",
			"limite_80": "You have reached more than 80% of your limit and a Renewal Request has been automatically generated. Pay that order and avoid blocking your Cashback.",

			"cred_ind_direta": "Direct referral credit",
			"id_td": "Direct indication",
			"ii_td": "Indirect indication",
			"cred_ind_indireta": "TEAM BONUS",
			"cred_mensalidade": "UNILEVEL",
			"debt_td": "Debt",
			"cred_binario": "Binary Credit",
			"transf_debito": "Debit Transfer",
			"transf_credito": "Credit Transfer",
			"ped_retirada": "Withdrawal Request",
			"extorno_td": "Withdrawal Return",
			"tx_adm_deb": "Administrate tax",
			"tx_adm_cred": "Administrate tax",
			"cred_saldo_disp": "Credit Balance Available",
			"deb_de_trader": "Trader's debt",
			"ajust_limite": "Threshold Adjustment",
			"cred_pontos": "Credit Points",
			"deb_saldo_disp": "Debit Balance Available",
			"pl_carreira": "Career path",
			"ganh_diario": "CASHBACK",
			"saq_duplicado": "Duplicate Withdrawal",
			"ajust_saldo": "Balance Adjustment",
			"tx_retirada": "Withdrawal Fee",
			"cred_pagar_com_saldo": "Credit Pay with Balance",
			"cred_upgrade": "UpGrade Credit",
			"limite_excedido": "Limit debt exceeded",
			"cred_renovacao": "Credit Renewal",
			"deb_saldo": "Debit Balance",
			"doacao_td": "Donation",
			"doa_men_receb": "Monthly Platform Donation Received",
			"bonus_saldo_trader": "Trader Balance Bonus",
			"cred_recb_trader": "Credit Receiving Trader",
			"deb_val_indev": "Debt Undue Amount",
			"cred_game_donate": "Game Donate Credit",
			"deb_game_donate": "Debit Game Donate",

			"ser_investidor": "To be an investor in our platform, you must be referred by someone.",
			"indicante_td": "Who is your Indicating?",
			"escreva_indicante": "Write the username of your Indicant",
			"erro_user_msg": "Error when finding Login",
			"aceite_td": "I accept",
			"terms_td": "terms and conditions",
			"indicado_por": "You are being indicated by:",
			"cadastrado_td": "Already registered? Click here and log in!",
			"ainda_não_cadastrado": "Not yet registered?",
			"senha_nao_confere": "The password and confirmation should be equal",
			"senhaf_nao_confere": "The financial password and confirmation should be equal",
			"login_ja_tem": "This login already exists! Please choose another one!",
			"invalido_td": "Invalid",
			"esqueci_td_success": "Password changed successfully, check your e-mail!",
			"empty_td": "Empty",
			"objetivo_td": "Objective",
			"info_pessoal": "Personal Information",

			"semsaldo_td": "Insufficient balance",
			"pacotemenor_td":"Can only take out until the value of your package per day",
			"body_mensalidade":"Your UNILEVEL has expired and a new payment order has been generated, please pay the order and receive your Cashback again",
			"body_dia_primeiro":"Make your monthly activation and guarantee your PIX MANIA card activated and also your UNILEVEL bonus.",

			"complemento_td":"Complement",
			"nascimento_td":"Birth date",
			"documento_td":"Passport",
			"documento_repetido":"Registered Document",
			"saque_minimo":"Minimum withdrawal",
			"aleatoria_td":"Random",
			"forma_td":"Form of receipt",
			"enviar_link":"Send video link",
			"url_video":"Enter the video URL",
			"link_enviado":"Video link sent successfully!",
			"link_nao_enviado":"Video link not sent!",
			"utilizado_td":"Used",
			"recusado_td":"Refused"
		}
	}
}

export { messages }