import React from 'react'
import {Modal} from 'react-bootstrap';

export default function Modals(props) {
  return (
    <Modal {...props} size={props.size}>
		{props.children}
    </Modal>
  );
}
