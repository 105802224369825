import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {Col, Row, Card, Button, Alert, Spinner, ProgressBar} from 'react-bootstrap'
import CardOrder from '../../Components/SubComponents/CardOrder'
import {i18n} from '../../Components/Translates/i18n'
import Modals from '../../Components/SubComponents/Modals'
import { FaBtc, FaEraser, FaEye, FaQrcode } from 'react-icons/fa'

export default function Order() {
	// const token = process.env.REACT_APP_USER_TOKEN;
	document.title = `${i18n.t('pedido_td')} | ${process.env.REACT_APP_NAME}`
	
	const token = sessionStorage.getItem('token');

  	const [orders, setOrders] = useState([])
	const [idApagar, setIdApagar] = useState()
	const [pedId, setPedId] = useState()
	const [modalShow, setModalShow] = useState(false)
	const [modalWalletShow, setModalWalletShow] = useState(false)
	const [modalPicpayShow, setModalPicpayShow] = useState(false)
	const [modalPixShow, setModalPixShow] = useState(false)
	const [modalComprovanteShow, setModalComprovanteShow] = useState(false)
	const [pixValor, setPixValor] = useState()
	const [pixPed, setPixPed] = useState()
	const [picPay, setPicPay] = useState([])
	const [user, setUser] = useState([])
	const [imgProof, setImgProof] = useState('')

	const [fileSelect, setFileSelect] = useState([])

	const [countDown, setCountDown] = useState(0)
	let intervalRef = useRef()
	
	useEffect(() => {
		const getOrders = () => {
			window.scrollTo(0, 0)
			axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/pedidos/token/${token}`).then(success => {
				if(success.data.status){
					setOrders(success.data.dados)
				} else {
					sessionStorage.removeItem('token')
					let local = window.location
					window.location = local
				}
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})
			window.scrollTo(0, 0)
		}
		getOrders()
	}, [token]);

	const apagar = data => {
		setIdApagar(data)
		setModalShow(true)
	}

	const doDelete = () => {
		axios.post(`${process.env.REACT_APP_URL_API}/ContaCorrente/pedidos/token/${token}`,idApagar).then(success => {
			if(success.data.status){
				axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/pedidos/token/${token}`).then(success => {
					if(success.data.status){
						setOrders(success.data.dados)
					} else {
						sessionStorage.removeItem('token')
						let local = window.location
						window.location = local
					}
				}).catch(error => {
					console.log(error)
					sessionStorage.removeItem('token')
					let local = window.location
					window.location = local
				})
				setModalShow(false)
			} else {
				alert('Order can not be deleted!')
			}
		}).catch(error => {
			console.log(error)
			sessionStorage.removeItem('token')
			let local = window.location
			window.location = local
		})
	}
	
	const gerarBtc = data => {
		document.getElementsByClassName('botao')[0].style.display = 'none'
		document.getElementsByClassName('loading')[0].style.display = 'block'
		let moeda = 'btc'
		axios.get(`${process.env.REACT_APP_URL_API}/Block/gerar_pedido/moeda/${moeda}/pedido/${data}/token/${token}`).then(success => {
			if(success.data.status){
				setUser(success.data.user[0])
				setPedId(data)
				setModalWalletShow(true)
				document.getElementsByClassName('loading')[0].style.display = 'none'
			}
		}).catch(error => {
			console.log(error)
			/* sessionStorage.removeItem('token')
			let local = window.location
			window.location = local */
		})
	}
	const timer = 10800
	const gerarPicPay = data => {
		axios.get(`${process.env.REACT_APP_URL_API}/Block/gerar_picpay/pedido/${data}/token/${token}`).then(success => {
			if(success.data.status){
				setPicPay(success.data)
				setModalPicpayShow(true)
				setCountDown((new Date(success.data.expires).getTime()/1000) - (new Date(success.data.now).getTime()/1000))
				const decreaseNum = () => setCountDown(prev => {
					if(prev === 0){
						let local = window.location
						window.location = local
					} else {
						return prev -1
					}
				})
				intervalRef.current = setInterval(decreaseNum, 1000)
			} else {
				console.log(success.data)
			}
		}).catch(error => {
			console.log(error)
			/* sessionStorage.removeItem('token')
			let local = window.location
			window.location = local */
		})
	}

	const sendProof = order => {
		const fd = new FormData()
		fd.append('file_arquivo', fileSelect, fileSelect.name)
		axios.post(`${process.env.REACT_APP_URL_API}/Pedido/sendProof/Order/${order}/token/${token}`, fd).then(success => {
			if(success.data.status){
				setImgProof(success.data.imgProof)
				alert(`${i18n.t('comprovante_msg')}: ${i18n.t('sucesso_td')}`)
				setModalComprovanteShow(false)
			} else {
				alert(`${i18n.t('comprovante_msg')}: ${i18n.t('erro_td')}`)
			}
		})
	}

	return (
		<>
			<Row>
				<Col>
					<div className="p-2">
						<h4 className="text-white">{i18n.t('pagar_td')} {i18n.t('pedido_td')}</h4>
						<Row>
							{orders ? 
								orders.map((item, index) => {
									return (
										<Col md="6" xl="4" key={index}>
											<CardOrder
											bg="default"
											color="light"
											title={i18n.t('numero_td') + ' ' + i18n.t('pedido_td') + ': ' + item.ped_id}
											subtitle={i18n.t('desc_td') + ': ' + i18n.t(item.pro_nome)}
											status={item.fk_status === 3 ? "success" : "danger"}
											list={[
													{
														visible: true,
														title: i18n.t('data_td'),
														valor: new Intl.DateTimeFormat('pt-BR').format(new Date(item.ped_data))
													}, 
													{
														visible: true,
														title: i18n.t('status_td'),
														valor: item.fk_status === 3 ? i18n.t('pago_td') : i18n.t('pendente_td'),
														badge: item.fk_status === 3 ? "success" : "danger"
													}, 
													{
														visible: item.ped_data_pag ? true : false,
														title: i18n.t('data_td') + ' ' + i18n.t('de_td') + ' ' + i18n.t('pagamento_td'),
														valor: new Intl.DateTimeFormat('pt-BR').format(new Date(item.ped_data_pag))
													}, 
													{
														visible: true,
														title: i18n.t('total_td'),
														valor: `US$ ${item.ped_total}`
													},
													{
														visible: item.ped_total === item.ped_valor ? false : true,
														title: `${i18n.t('efetuado_td')} ${i18n.t('saldo_td')}`,
														valor: `US$ ${item.ped_total - item.ped_valor}`
													}
												]}
											>
											{item.fk_status === 2
											? <>
												<Card.Footer variant="dark">
													<Button variant="warning" className="botao" onClick={e => gerarBtc(item.ped_id)}><FaBtc /> Bitcoin</Button>
													
													<Button variant="success" onClick={e => gerarPicPay(item.ped_id)}><FaQrcode /> PicPay</Button>

													<Button variant="light" onClick={() => {setPixValor(item.ped_valor_reais); setPixPed(item.ped_id); setImgProof(item.comprovante); setModalPixShow(true)}}><FaQrcode /> PIX</Button>

													<Spinner animation="border" className="loading" style={{display: 'none'}}/>
													
													<Button variant="danger" onClick={e => apagar(item.ped_id)}><FaEraser /> {i18n.t('apagar_td')}</Button>
												</Card.Footer>
											</>
											: ''}
											</CardOrder>
										</Col>
									)
								})
							: ''}
						</Row>
					</div>
				</Col>
			</Row>
			<Modals
				size="lg"
				title={`${i18n.t('apagar_td')} ${i18n.t('pedido_td')} ${idApagar}`}
				// contentClassName="bg-dark"
				show={modalShow}
				onHide={() => setModalShow(false)}
			>
				<Row>
					<Col md="9">
						<h3>{`${i18n.t('confirmar_td')} ${i18n.t('apagar_td')} ${i18n.t('pedido_td')} ${idApagar}?`}</h3>
					</Col>
					<Col md="3">
						<Button variant="danger" size="lg" onClick={() => doDelete()}>{i18n.t('apagar_td')}</Button>
					</Col>
				</Row>
			</Modals>
			
			<Modals
				size="lg"
				// contentClassName="bg-dark"
				show={modalWalletShow}
				onHide={() => setModalWalletShow(false)}
			>
				<Row>
					<Col>
						<Alert variant="success">
							<strong>
								{i18n.t('msg_wallet_email')} ({pedId}), 
								{i18n.t('msg_wallet_email2')}: <br/>
								{user.usu_email} <br/>
								{i18n.t('msg_wallet_email3')}
							</strong>
						</Alert>
					</Col>
				</Row>
			</Modals>

			<Modals
				size="lg"
				// contentClassName="bg-light"
				show={modalPicpayShow}
				onHide={() => setModalPicpayShow(false)}
				title='Pague com PicPay'
			>
				<div className="scope" style={{
						marginTop: '30px',
						textAlign: 'center',
						marginLeft: 'auto',
						marginRight: 'auto',
						minWidth: '200px',
					}}>
					<div className="logo" style={{
							fill: '#21c25e',
							width: '35%',
							marginLeft: 'auto',
							marginRight: 'auto',
							paddingBottom: '20px',
						}}>
						<svg xmlns="http://www.w3.org/2000/svg" id="svg" viewBox="0 0 129 44"><path className="logo" d="M28 34.3h5.8V17.4H28V34.3zM35.8 5.9h-3.9v3.9h3.9V5.9zM13.9 7.8H8.3v4.9h5.2c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7H8.3v-9.2H2.5v21.5h5.8v-7.4h5.5c6.7 0 10.6-3.6 10.6-9.8C24.4 11.3 20.6 7.8 13.9 7.8zM39.7 2H28v11.7h11.7V2zM37.8 11.7H30V3.9h7.8V11.7zM71.8 7.8h-5.3v4.9h5c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7h-5v-9.2h-5.8v21.5h5.8v-7.4h5.3c6.7 0 10.6-3.6 10.6-9.8C82.4 11.3 78.5 7.8 71.8 7.8zM120.5 14l-5 12.6 -5-12.6h-6l8 20.3 -3.1 7.7h6.1l11-28H120.5zM94.5 13.9c-3.5 0-6.2 0.8-9.2 2.3l1.8 4c2.1-1.2 4.2-1.8 6.1-1.8 2.8 0 4.2 1.2 4.2 3.4v0.4h-5.6c-5 0-7.7 2.3-7.7 6.1 0 3.7 2.6 6.3 7 6.3 2.8 0 4.8-1 6.4-2.7v2.2h5.7l0-13.2C103 16.6 99.9 13.9 94.5 13.9zM97.9 27.5c-0.6 1.7-2.3 3.1-4.7 3.1 -2 0-3.2-1-3.2-2.6 0-1.6 1.1-2.3 3.3-2.3h4.6V27.5zM48.6 29.9c-2.8 0-4.8-2.2-4.8-5.5 0-3.2 2-5.4 4.8-5.4 2 0 3.5 0.8 4.6 2.2l3.9-2.8c-1.8-2.7-4.9-4.3-8.8-4.3C42.2 14 38 18.2 38 24.4c0 6.2 4.2 10.3 10.3 10.3 4.2 0 7.3-1.7 9-4.5l-4-2.7C52.3 29.1 50.7 29.9 48.6 29.9z"></path></svg>
					</div>
					<div>
						
						<p>Abra o PicPay em seu telefone e escaneie o código abaixo:</p>
						<h4>Expira em: {picPay.expires} UTC</h4>
						<ProgressBar variant="danger" animated now={((timer-countDown)/timer)*100} style={{height: '30px', maxWidth: '400px', margin: '10px auto'}}/>

						<a href={picPay.paymentUrl} rel="noreferrer noopener" target="_blank"><img className="img-fluid" src={picPay.qrcode} alt="qrCode"/></a>

						
						<div className="message-qrcode">
							<p><strong>Se tiver algum problema com a leitura do QR code, atualize o aplicativo. </strong></p>
							<p><a target="_blank" href="https://meajuda.picpay.com/hc/pt-br/articles/360045117912-Quero-fazer-a-adi%C3%A7%C3%A3o-mas-a-op%C3%A7%C3%A3o-n%C3%A3o-aparece-para-mim-E-agora-" rel="noreferrer noopener">Saiba como atualizar o aplicativo</a></p>
						</div>
					</div>
				</div>
			</Modals>

			<Modals
				size="lg"
				// contentClassName="bg-light"
				show={modalPixShow}
				onHide={() => setModalPixShow(false)}
				title='Pague com Pix'
			>
				<div className="p-2 card radius-15">
					<h3>Chave para pagamento:</h3>
					<h1>somostodospixmania@gmail.com</h1>
					<h3>Valor: R${pixValor}</h3>
				</div>
				<div className="p-2 card radius-15">
					<button className="btn btn-primary" onClick={() => {setModalComprovanteShow(true); setModalPixShow(false)}}>
						Enviar Comprovante
					</button>
				</div>
			</Modals>

			<Modals
				size="lg"
				// contentClassName="bg-light"
				show={modalComprovanteShow}
				onHide={() => setModalComprovanteShow(false)}
				title='Pague com Pix'
			>
				<h5>{i18n.t('comprovante_msg')} # {pixPed}</h5>
				<div className="card">
					{imgProof?
						<a href={`/comprovantes/${imgProof}`} className="btn btn-success btn-lg" target="_blank" rel="noopener noreferrer"><FaEye/> </a>
					:''}
				</div>
				<div className="input-group">
					<input type="file" className="form-control" onChange={e => setFileSelect(e.target.files[0])} />
					<div className="input-group-append">
						<button className="btn btn-primary" onClick={e => sendProof(pixPed)}>{i18n.t('comprovante_msg')}</button>
					</div>
				</div>
			</Modals>
		</>
	)
}