import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Fa from 'react-icons/fa'
import { i18n } from './Translates/i18n';

export default function NavBar() {
	const token = sessionStorage.getItem('token');
	const [usuarioy, setUsuarioy] = useState([]);
	const [chamado, setChamado] = useState([]);
	
	const I18N_STORAGE_KEY = 'i18nextLng'
	const [language, setLanguage] = useState(localStorage.getItem(I18N_STORAGE_KEY))
	const handleLanguage = e => {
		setLanguage(e)
		localStorage.setItem(I18N_STORAGE_KEY, e)
		let location = window.location
		window.location = location
	}

	useEffect(() => {
		const user = () => {
			axios.get(`${process.env.REACT_APP_URL_API}/Bo/main/token/${token}`).then(success => {
				setUsuarioy(success.data.usuarioy)
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})

			axios.get(`${process.env.REACT_APP_URL_API}/Bo/home/token/${token}`).then(success => {
				setChamado(success.data.chamado)
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})
		}
		user()
	}, [token]);

	const ReadCall = (data) => {
		sessionStorage.setItem('idCall', data)
		window.location = '/backoffice/readcall'
	}
	
	return (
		<>
			<header className="top-header">
				<nav className="navbar navbar-expand">
					<div className="sidebar-header">
						<div className="d-none d-lg-flex">
							<Link to="/backoffice/bo"><img src="/logos/logo.png" height="60" alt="{process.env.REACT_APP_NAME}" /></Link>
						</div>
						<a href="true" onClick={e => e.preventDefault()} className="ml-auto toggle-btn p-auto"> <Fa.FaBars/></a>
					</div>
					
					<div className="ml-auto right-topbar">
						<ul className="navbar-nav">
							<li className="nav-item dropdown dropdown-lg">
								<Button variant="link" className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" data-toggle="dropdown"><span className="msg-count">{chamado.length}</span>
									<i className="bx bx-comment-detail vertical-align-middle"></i>
								</Button>
								<div className="dropdown-menu dropdown-menu-right">
									<Link to="/backoffice/call">
										<div className="msg-header">
											{i18n.t('todos_td')} <Fa.FaArrowRight/>
										</div>
									</Link>
									<div className="header-message-list" style={{overflowY: 'scroll'}}>
										{chamado ? 
											chamado.map((lista, index) => {
												return (
													<React.Fragment key={index}>
														{index < 10 ?
															<Button variant="link" className="dropdown-item" onClick={e => ReadCall(lista.id)}>
																<div className="media align-items-center">
																	<div className="mr-2 media">
																		<Fa.FaEnvelope />
																	</div>
																	<div className="media-body">
																		<h6 className="msg-name">{lista.assunto}</h6>
																		<small className="d-flex justify-content-between">
																			{lista.datahj}
																			{lista.status === 1
																			? <Badge variant="warning">{i18n.t('aberto')}</Badge>
																			: lista.status === 2
																			? <Badge variant="info">{i18n.t('respondido')}</Badge>
																			: lista.status === 3 
																			? <Badge variant="success">{i18n.t('respondido')}</Badge>
																			: ''}
																		</small>
																	</div>
																</div>
															</Button>
														: ''}
													</React.Fragment>
												)
											})
										:''}
									</div>
								</div>
							</li>
							<li className="nav-item dropdown dropdown-user-profile">
								<Button variant="link" className="nav-link dropdown-toggle dropdown-toggle-nocaret" data-toggle="dropdown">
									<div className="media user-box align-items-center">
										<div className="media-body user-info">
											<p className="mb-0 user-name">{usuarioy.usu_nome}</p>
											<p className="mb-0 designattion">{usuarioy.usu_usuario}</p>
										</div>
										<img src={`/fotos/${usuarioy.foto}`} className="user-img" alt="user avatar"/>
									</div>
								</Button>
								<div className="dropdown-menu dropdown-menu-right">	
									<Link to="/backoffice/profile">
										<Button variant="link" className="dropdown-item">
											<i className="bx bx-user"></i><span>{i18n.t('dados_td')} {i18n.t('pessoais_td')}</span>
										</Button>
									</Link>
									<Link to="/backoffice/password">
										<Button variant="link" className="dropdown-item">
											<i className="bx bx-cog"></i><span>{`${i18n.t('senha_td')} ${i18n.t('de_td')} ${i18n.t('acesso_td')}`}</span>
										</Button>
									</Link>
									<Link to="/backoffice/financepassword">
										<Button variant="link" className="dropdown-item">
											<i className="bx bx-tachometer"></i><span>{`${i18n.t('senha_td')} ${i18n.t('financeiro_td')}`}</span>
										</Button>
									</Link>
									<div className="mb-0 dropdown-divider"></div>
									<Link to="/backoffice/logout">
										<Button className="dropdown-item">
											<i className="bx bx-power-off"></i><span>{i18n.t('sair_td')}</span>
										</Button>
									</Link>
								</div>
							</li>
							<li className="nav-item dropdown dropdown-language">
								<Button variant="link" className="nav-link dropdown-toggle dropdown-toggle-nocaret" data-toggle="dropdown">
									<div className="lang d-flex">
										{
											language === 'pt-BR'? <><div><i className="flag-icon flag-icon-pt"></i></div><div><span>PT</span></div></>
											: language === 'es'? <><div><i className="flag-icon flag-icon-es"></i></div><div><span>ES</span></div></>
											: language === 'fr'? <><div><i className="flag-icon flag-icon-fr"></i></div><div><span>FR</span></div></>
											: <><div><i className="flag-icon flag-icon-um"></i></div><div><span>EN</span></div></>
										}
									</div>
								</Button>
								<div className="dropdown-menu dropdown-menu-right">
									<Button variant="link" className="dropdown-item" onClick={e => handleLanguage('en-US')}>
										<i className="flag-icon flag-icon-um"></i><span>English</span>
									</Button>
									<Button variant="link" className="dropdown-item" onClick={e => handleLanguage('es')}>
										<i className="flag-icon flag-icon-es"></i><span>Español</span>
									</Button>
									<Button variant="link" className="dropdown-item" onClick={e => handleLanguage('fr')}>
										<i className="flag-icon flag-icon-fr"></i><span>Français</span>
									</Button>
									<Button variant="link" className="dropdown-item" onClick={e => handleLanguage('pt-BR')}>
										<i className="flag-icon flag-icon-pt"></i><span>Português</span>
									</Button>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</header>
		</>
	)
}