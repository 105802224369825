const messages = {
	pt: {
		translations: {
			"pt_diferenca_td": "Pontos de Diferença Anterior",
			"msg_saque_dia_td": "Em razão do sucesso mundial e o constante volume de transações no sistema, \na frequencia de saques são diários.",
			"sem_virgulas": "Não use vírgulas para os decimais. Use pontos. Ex.: 99.50",
			"pagante_td": "Pagante",
			"lider_lib": "Líder Liberado",
			"lider_bloq": "Líder Bloqueado",
			"voucher_td": "Voucher",
			"auth_td": "Autenticação em Dois Fatores",
			"auth_ok": "Sua conta está protegida com a verificação em duas etapas",
			"auth_ok_sub": "Sempre que você fizer login na sua Conta, será necessário usar sua senha e um código de verificação exclusivo que foi gerado pelo App Google Authenticator no seu Smartphone.",
			"no_auth_td": "Desabilitar Autenticação em Dois Fatores",
			"proteja_2fa": "Proteja sua conta com a verificação em duas etapas",
			"proteja_2fa_sub": "Sempre que você fizer login na sua Conta, será necessário usar sua senha e um código de verificação.",
			"addcamada_td": "Adicionar uma camada extra de segurança",
			"addcamada_sub": "Digite sua senha e o código de verificação exclusivo que foi gerado pelo App Google Authenticator no seu Smartphone.",
			"keep_away_td": "Mantenha as pessoas mal-intencionadas afastadas",
			"keep_away_sub": "Mesmo se alguém conseguir sua senha, isso não será suficiente para fazer login na sua conta.",
			"do_auth_td": "Habilitar Autenticação em Dois Fatores",
			"siga_passos_td": "Siga esses passos para habilitar a Autenticação em Dois Fatores",
			"step_1": "Abra o App Google Authenticator no seu smartphone.",
			"step_2": "Clique no botão de adicionar e depois clique em Ler código QR.",
			"step_3": "Aponte a camera do seu smartphone para o código abaixo:",
			"dig_td": "Digite o Código de Verificação",
			"code_verific_td": "Código de Verificação",
			"code_verific_sub": "Digite o código que está aparecendo no seu smartphone. Se o tempo acabar, é só digitar o novo código que apareceu.",
			"doverify_td": "Verificar",
			"invalid_td": "Código de Verificação Inválido",
			"load_qr": "Carregando QR Code...",
			"dig_login_td": "Insira o código de verificação gerado no aplicativo Google Authenticator",
			"materiais_td": "Materiais de Apoio",
			"msg_sucesso": "Seu boleto foi gerado com sucesso e foi encaminhado para seu e-mail de cadastro",
			"msg_btc": "Carteira Inválida",
			"msg_renovacao": "Você atingiu seu limite de ganho, renove seu pacote.",
			"info_endereco": "Preencha todos os campos abaixo",
			"plano_titulo": "Plano de carreira",
			"msg_carreira": "Seu novo plano de carreira foi atualizado",
			"completo_percent": "Completo",
			"pacotes_tb": "Lista de Pacotes",
			"view_qtd": "Quantidade",
			"msg_pacote": "Pacote escolhido com sucesso",
			"msg_pacote_erro": "Não foi possível finalizar",
			"msg_pacote_vazio": "Você precisa escolher um pacote",
			"escolha_pacotes": "Escolha um Pacote",
			"usuario_td": "Usuário",
			"erro_logar": "Erro ao Logar",
			"fk_matriz": "Matriz",
			"erro_logar_msg": "Usuário ou Senha Inválida",
			"senha_td": "Senha",
			"esqueci_td": "Esqueci a Senha",
			"acesso_td": "Acesso",
			"de_td": "de",
			"seg_td": "Segurança",
			"confirmar_td": "Confirmar",
			"email_td": "Email",
			"enviar_td": "Enviar",
			"reenviar_td": "Reenviar",
			"visualizar_td": "Visualizar",
			"dias_td": "Dias",
			"horas_td": "Horas",
			"minutos_td": "Minutos",
			"pais_td": "País",
			"paises_td": "Países",
			"estado_td": "Estado",
			"cidade_td": "Cidade",
			"banco_de_dados": "Banco de Dados",
			"meus_contatos": "Meus Contatos",
			"banco_empresa": "Banco da Empresa",
			"escolher_td": "Escolher",
			"todos_td": "Todos",
			"segunda_td": "Segunda",
			"terca_td": "Terça",
			"quarta_td": "Quarta",
			"quinta_td": "Quinta",
			"sexta_td": "Sexta",
			"sabado_td": "Sábado",
			"domingo_td": "Domingo",
			"seg_sex": "Segunda a Sexta",
			"sab_dom": "Sábado e Domingo",
			"manha_td": "Manhã",
			"tarde_td": "Tarde",
			"noite_td": "Noite",
			"ativar_conta": "Entre em sua conta de e-mail e Confirme seu Cadastro",
			"ativar_zap": "Enviamos uma mensagem para o WhatsApp:",
			"favor_zap": "Valide seu WhatsApp clicando no link de ativação na mensagem que enviamos para você.",
			"sim_td": "Sim",
			"nao_td": "Não",
			"parabens_td": "Parabéns",
			"sucesso_td": "Sucesso",
			"erro_td": "Erro",
			"obrigado_td": "Obrigado",
			"cadastrar_td": "Cadastrar",
			"gravar_td": "Gravar",
			"salvar_td": "Salvar",
			"agora_td": "Agora",
			"postador_td": "Postador",
			"texto_td": "Texto",
			"imagem_td": "Imagem",
			"ddi_pais": "Código do País",
			"cancelar_td": "Cancelar",
			"continuar_td": "Continuar",
			"parar_td": "Parar",
			"zap_td": "WhatsApp",
			"celular_td": "Celular",
			"telefone_td": "Telefone",
			"nome_td": "Nome",
			"endereco_td": "Endereço",
			"logradouro_td": "Logradouro",
			"numero_td": "Número",
			"numeros_td": "Números",
			"bairro_td": "Bairro",
			"comp_td": "Complemento",
			"uf_td": "UF",
			"cep_td": "CEP",
			"entrega_td": "Entrega",
			"data_td": "Data",
			"nasc_td": "Nascimento",
			"plano_td": "Plano",
			"bem_vindo": "Seja Bem Vindo",
			"efetuado_td": "Efetuado com",
			"status_td": "Status",
			"upgrade_td": "Upgrade",
			"financeiro_td": "Financeiro",
			"principal_td": "Início",
			"banco_td": "Bancos",
			"bancarios_td": "Bancários",
			"rede_td": "Rede",
			"binario_td": "Binário",
			"direto_td": "Diretos",
			"linear_td": "Linear",
			"matriz_td": "Matriz",
			"trans_td": "Transferência",
			"saldo_td": "Saldo",
			"pg_saldo": "Pagar com Saldo",
			"liberado_td": "Liberado",
			"bloqueado_td": "Bloqueado",
			"pendente_td": "Pendente",
			"dados_td": "Dados",
			"pessoais_td": "Pessoais",
			"nivel_td": "Nível",
			"buscar_td": "Buscar",
			"moeda_td": "Moeda",
			"dinheiro_td": "Dinheiro",
			"carreira_td": "Carreira",
			"subir_td": "Subir",
			"foto_td": "Foto",
			"msg_td": "Mensagem",
			"aqui_td": "Aqui",
			"extrato_td": "Extrato",
			"sair_td": "Sair",
			"logar_td": "Logar",
			"loja_td": "Loja",
			"alterar_td": "Alterar",
			"perna_td": "Perna",
			"direita_td": "Direita",
			"esquerda_td": "Esquerda",
			"pedido_td": "Pedido",
			"btc_td": "Bitcoin",
			"sacar_td": "Sacar",
			"taxa_td": "taxa",
			"estorno": "Estorno",
			"link_de": "Link de Cadastro",
			"patrocinador_td": "Patrocinador",
			"qualif_td": "Qualificado",
			"qualificacao_td": "Qualificação",
			"renovar_td": "Renovar",
			"ativo_td": "Ativo",
			"inativo_td": "Inativo",
			"comprar_td": "Comprar",
			"comprar_prod": "Comprar Produtos",
			"titulo_msg": "ATENÇÃO",
			"erro_msg": "Não foi possível gerar o pedido",
			"titulo_sucesso": "PARABÉNS",
			"sucesso_msg": "Pedido Gerado com Sucesso",
			"pagar_td": "Pagar",
			"realmente": "Realmente Deseja",
			"valor_exato": "Pague o Valor Acima exatamente igual",
			"com_td": "com",
			"do_td": "do",
			"virgula_td": ",",
			"apagar_td": "Apagar",
			"limite_td": "Limite",
			"entrada_td": "Entrada",
			"saida_td": "Saída",
			"total_td": "Total",
			"sub_total": "Sub-Total",
			"participe_td": "Participe",
			"cota_td": "Cotas",
			"divisao_lucro": "Divisão de Lucro",
			"configura_td": "Configurações",
			"aviso_td": "Aviso",
			"carrinho_td": "Carrinho",
			"vazio_td": "Este campo não pode ser vazio",
			"abrir_td": "Abrir",
			"fechar_td": "Fechar",
			"cpf_td": "CPF",
			"menu_td": "Menu",
			"valor_td": "Valor",
			"pref_td": "Preferencial",
			"auto_td": "Perna Menor",
			"escolha_td": "Escolha",
			"disponivel_td": "Disponível",
			"pagamento_td": "Pagamento",
			"boleto_td": "Boleto",
			"opcao_td": "Opções",
			"pago_td": "Pago",
			"desc_td": "Descrição",
			"cred_td": "Crédito",
			"deb_td": "Débito",
			"tipo_td": "Tipo",
			"privado_td": "Privado",
			"grupo_td": "Grupo",
			"tipo_conta": "Tipo da Conta",
			"agencia_td": "Agência",
			"conta_td": "Conta",
			"dv_td": "Dígito",
			"favorecido_td": "Favorecido",
			"corrente_td": "Corrente",
			"poupanca_td": "Poupança",
			"update_td": "Atualizar",
			"escreva_td": "Escreva",
			"reescreva_td": "Reescreva",
			"ate_td": "até",
			"nova_td": "Nova",
			"atual_td": "Atual",
			"antiga_td": "Antiga",
			"carteira_td": "Carteira",
			"pix_td": "Chave Pix",
			"saque_td": "Saque",
			"brinde_td": "Brinde",
			"ativar_td": "Ativar",
			"upline_td": "Upline",
			"frete_td": "Frete",
			"passar_prazo_td": "Se passar do prazo deve gerar novamente",
			"potencial_td": "Potencial de Ganhos",
			"ficticio_vl": "Valores Fictícios",
			"ja_ganhei": "Já Ganhei na",
			"campanha_td": "Campanha",
			"adicionar_td": "Adicionar",
			"criar_td": "Criar",
			"base_empresa": "Lista da Empresa",
			"minha_base": "Minha Lista",
			"ferramentas_td": "Ferramentas",
			"licencas_td": "Licenças",
			"serial_td": "Serial",
			"videos_td": "Vídeo Aula",
			"arquivo_td": "Arquivo",
			"importar_td": "Importar",
			"exportar_td": "Exportar",
			"bonus_td": "Bônus",
			"investidor_td": "Investidor",
			"simples_td": "Simples",
			"novamente_td": "Novamente",
			"robo_td": "Robô",
			"segmentado_td": "Segmentado",
			"text_numeros": "Números ou Link de Grupos separados por ; exemplo: xxxxxx;xxxxxx;xxxxxx",
			"envia_100": "Envia 100 Contatos de",
			"msg_moderacao": "Atenção! Utilize o Robô com moderação, caso tenha receio não utilize seu chip pessoal.",
			"modo_td": "Modo",
			"msg_cad": "Para continuar o cadastro escolha um paconte!",
			"emocao_td": "Cada hora uma nova Emoção!",
			"pack": "Meu Pacote",
			"data_ativa": "Data de Ativação",
			"qualif_binario": "Qualificação no Binário",
			"plano_al": "Plano de Carreira",
			"qtd_rede": "Qtd Rede",
			"sys_tb": "No sistema",
			"saque_td2": "Retirada",
			"saque_td3": "Com sucesso",
			"ate_hj": "Até Hoje",
			"entrada_et": "Entrada",
			"tb_usuarios": "Usuários",
			"tb_usando_sys": "No sistema",
			"plano_main": "Alterar Plano",
			"plano_main2": "Desejo alterar meu plano",
			"escolha_plano": "Escolha um novo plano abaixo",
			"package_plano": "Pacote",
			"escolher_plano": "Escolher",
			"total_e": "Total a Esquerda",
			"total_d": "Total a Direita",
			"usu_rede": "Login do Usuário abaixo da sua Rede",
			"voltar_top": "Voltar ao Topo",
			"sub_nivel": "Subir um Nível",
			"list_p": "Lista dos Pontos",
			"ped_b": "Pedido",
			"ped_bs": "Pedidos",
			"usu_b": "Usuário",
			"data_b": "Data",
			"pts_b": "Pontos",
			"log_usu": "Digite o login do usuário",
			"total_ee": "Esquerda",
			"total_dd": "Direita",
			"limit_b": "Limite",
			"senha_up": "Alterar Senha",
			"senha_f": "Alterar Senha Financeira",
			"taxa": "Taxa",
			"comprovante_msg": "Enviar Comprovante",
			"selecione_tb": "Selecione uma Foto",
			"chamado": "Abrir um Chamado",
			"list_chamado": "Lista de Chamados",
			"n_chamado": "Nº",
			"res_data": "Resposta",
			"ass_chamado": "Assunto",
			"respondido": "Respondido",
			"aberto": "Em Aberto",
			"depart": "Escolha um Departamento",
			"finan": "Financeiro",
			"support": "Suporte",
			"repod": "Responder",
			"succ_call": "Chamado aberto com sucesso",
			"err_call": "Não foi possível abrir um chamado",
			"succ_chamado": "Seu chamado foi enviado com sucesso",
			"err_chamado": "Não foi possível enviar sua mensagem",
			"atencao_td": "Atenção",
			"senha_valid": "Senha Inválida, verifique seus dados e tente novamente!",
			"success_senhaf": "Senha confirmada com sucesso!",
			"carteira_td_succss": "Carteira registrada com sucesso, verifique seu e-mail para ativar!",
			"pix_td_succss": "PIX resgistrado com sucesso, verifique seu e-mail para ativar!",
			"saque_email_td": "Saque solicitado, verifique seu e-mail e clique no link para autorizar!",
			"msg_wallet_email": "Para sua maior segurança, os dados para pagamento deste pedido ",
			"msg_wallet_email2": "foram enviados para o email ",
			"msg_wallet_email3": "por favor, confira o número do pedido e a data limite para o pagamento informados no email.",
			"carteira_td_erro": "Não foi possível registrar sua carreira, verifique seus dados e tente novamente!",
			"msg_btc_vazio": "Preencha o campo Bitcoin",
			"cadastre_carteira": "Cadastre uma Carteira BTC ou uma Chave Pix para realizar saques",
			"sacar_api_td": "Pagar API",
			"limite_80": "Você atingiu mais de 80% do seu limite e foi gerado automaticamente um Pedido de Renovação. Pague esse pedido e evite o Bloqueio dos seus Cashbacks.",

			"cred_ind_direta": "Crédito de indicação direta",
			"id_td": "Indicação Direta",
			"ii_td": "Indicação Indireta",
			"cred_ind_indireta": "BÔNUS EQUIPE",
			"cred_mensalidade": "UNILEVEL",
			"debt_td": "Debito",
			"cred_binario": "Crédito Binário",
			"transf_debito": "Transferência de Débito",
			"transf_credito": "Transferência de Crédito",
			"ped_retirada": "Pedido de Retirada",
			"extorno_td": "Extorno",
			"tx_adm_deb": "Taxa Administrativa",
			"tx_adm_cred": "Taxa Administrativa",
			"cred_saldo_disp": "Crédito Saldo Disponível",
			"deb_de_trader": "Débito de Trader",
			"ajust_limite": "Ajuste de Limite",
			"cred_pontos": "Crédito Pontos",
			"deb_saldo_disp": "Débito Saldo Disponível",
			"pl_carreira": "Plano de Carreira",
			"ganh_diario": "CASHBACK",
			"saq_duplicado": "Saque Duplicado",
			"ajust_saldo": "Ajuste de Saldo",
			"tx_retirada": "Taxa de Retirada",
			"cred_pagar_com_saldo": "Crédito Pagar com Saldo",
			"cred_upgrade": "Crédito UpGrade",
			"limite_excedido": "Débito de limite excedido",
			"cred_renovacao": "Crédito Renovação",
			"deb_saldo": "Débito Saldo",
			"doacao_td": "Doação",
			"doa_men_receb": "Doação da Plataforma Mensal Recebida",
			"bonus_saldo_trader": "Bônus Saldo Trader",
			"cred_recb_trader": "Crédito Receber Trader",
			"deb_val_indev": "Débito Valor Indevido",
			"cred_game_donate": "Crédito Game Donate",
			"deb_game_donate": "Débito Game Donate",

			"ser_investidor": "Para ser um investidor em nossa plataforma, você deve ser encaminhado por alguém.",
			"indicante_td": "Quem é seu Indicante?",
			"escreva_indicante": "Escreva o nome de Usuário do seu indicante",
			"erro_user_msg": "Erro ao encontrar Usuário!",
			"aceite_td": "Aceito",
			"terms_td": "Termos e Condições",
			"indicado_por": "Você está sendo indicado por:",
			"cadastrado_td": "Já está cadastrado? clique aqui e faça o login!",
			"ainda_não_cadastrado": "Ainda não está cadastrado? ",
			"senha_nao_confere": "A senha e sua confirmação devem ser iguais",
			"senhaf_nao_confere": "A senha financeira e sua confirmação devem ser iguais",
			"login_ja_tem": "Esse Login já existe! por favor escolha outro!",
			"invalido_td": "Inválido",
			"esqueci_td_success": "Senha Alterada com sucesso, verifique seu e-mail!",
			"empty_td": "Vazio",
			"objetivo_td": "Objetivo",
			"info_pessoal": "informaçãoes Pessoais",

			"semsaldo_td": "Saldo Insuficiente",
			"pacotemenor_td":"Só pode sacar até o valor do seu pacote por dia",
			"body_mensalidade":"Seu UNILEVEL venceu e foi gerada nova uma ordem de pagamento, por favor pague o pedido e volte a receber seus Cashbacks",
			"body_dia_primeiro":"Faça a sua ativação mensal e garanta o seu cartão da PIX MANIA ativado e também o seu bônus UNILEVEL.",
			"complemento_td":"Complemento",
			"nascimento_td":"Data de Nascimento",
			"documento_td":"CPF",
			"documento_repetido":"Documento já cadastrado",
			"saque_minimo":"Saque Mínimo",
			"aleatoria_td":"Aleatória",
			"forma_td":"Forma de recebimento",
			"enviar_link":"Enviar link de vídeo",
			"url_video":"insira a URL do Vídeo",
			"link_enviado":"Link de Vídeo enviado com sucesso!",
			"link_nao_enviado":"Link de Vídeo não enviado!",
			"utilizado_td":"Utilizado",
			"recusado_td":"Recusado"
		}
	}
}

export { messages }