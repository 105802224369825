import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Link } from "react-router-dom"
import { Alert, Form, FormControl, InputGroup, Button, Badge, Spinner } from 'react-bootstrap'
import { i18n } from '../Components/Translates/i18n'
import ModalFeature from '../Components/SubComponents/ModalFeature'

export default function Register () {
	document.title = `${i18n.t('cadastrar_td')} | ${process.env.REACT_APP_NAME}`

	const [planos, setPlanos] = useState([])
	const [countries, setCountries] = useState([])
	const [plano, setPlano] = useState(1)
	const [login, setLogin] = useState('')
	const [usuario, setUsuario] = useState('')
	// const [carteira, setCarteira] = useState('')
	const [nome, setNome] = useState('')
	const [email, setEmail] = useState('')
	const [senha, setSenha] = useState('')
	const [confSenha, setConfSenha] = useState('')
	const [seg, setSeg] = useState('')
	const [confSeg, setConfSeg] = useState('')
	const [paises, setPaises] = useState('Brazil')
	const [ddi, setDdi] = useState(55)
	const [whats, setWhats] = useState('')
	const [aceite, setAceite] = useState(false)
	
	const [nascimento, setNascimento] = useState('')
	const [documento, setDocumento] = useState('')

	const [cep, setCep] = useState('')
	const [logradouro, setLogradouro] = useState('')
	const [numero, setNumero] = useState('')
	const [complemento, setComplemento] = useState('')
	const [bairro, setBairro] = useState('')
	const [cidade, setCidade] = useState('')
	const [estado, setEstado] = useState('')

	const [erroUsuario, setErroUsuario] = useState(false)
	const [erroCarteira, setErroCarteira] = useState(false)
	const [erroSenha, setErroSenha] = useState(false)
	const [erroSenhaF, setErroSenhaF] = useState(false)
	const [erroEmail, setErroEmail] = useState(false)
	const [erroCep, setErroCep] = useState(false)
	// const [erroDoc, setErroDoc] = useState(false)
	const [waiting, setWaiting] = useState(false)
	const [erroCadastro, setErroCadastro] = useState(false)

	const [modalTermShow, setModalTermShow] = useState(false)

	useEffect(() => {
		const verificar = () => {
			let indicante = sessionStorage.getItem('indicant')
			if(indicante){
				axios.get(`${process.env.REACT_APP_URL_API}/Bo/valid_user/user/${indicante}`).then(success => {
					setLogin(success.data.dados)
				}).catch(error => {
					window.location = '/indicant'
				})
				axios.get(`${process.env.REACT_APP_URL_API}/Bo/getPlanos`).then(success => {
					setPlanos(success.data.planos)
					setCountries(success.data.countries)
				}).catch(error => {
					window.location = '/indicant'
				})
			} else {
				window.location = '/indicant'
			}
		}
		verificar()
	}, [])

	const validNewUser = () => {
		let user = {
			usuario : usuario
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/Usuario/validarusuario`, user).then(success => {
			if(!success.data.status){
				setUsuario('')
				setErroUsuario(true)
			} else {
				setErroUsuario(false)
			}
		}).catch(error => {
			console.log(error)
		})
	}

	/* const validCarteira = () => {
		let user = {
			carteira : carteira
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/Usuario/valid_carteira_user`, user).then(success => {
			if(!success.data.status){
				setCarteira('')
				setErroCarteira(true)
			} else {
				setErroCarteira(false)
			}
		}).catch(error => {
			console.log(error)
		})
	} */

	const validSenha = () => {
		if (confSenha !== senha){
			setConfSenha('')
			setErroSenha(true)
		} else {
			setErroSenha(false)
		}
	}

	const validSenhaF = () => {
		if (confSeg !== seg){
			setConfSeg('')
			setErroSenhaF(true)
		} else {
			setErroSenhaF(false)
		}
	}

	const validEmail = () => {
		axios.get(`${process.env.REACT_APP_URL_API}/Usuario/ver_email/email/${email}`).then(success => {
			if(!success.data.status){
				setEmail('')
				setErroEmail(true)
			} else {
				setErroEmail(false)
			}
		}).catch(error => {
			console.log(error)
		})
	}

	const validCep = () => {
		if(paises === 'Brazil'){
			axios.get(`${process.env.REACT_APP_URL_API}/Usuario/ver_cep/cep/${cep}`).then(success => {
				if(!success.data.status){
					setLogradouro('')
					setNumero('')
					setComplemento('')
					setBairro('')
					setCidade('')
					setEstado('')
					setErroCep(true)
				} else {
					setCep(success.data.viacep.cep)
					setLogradouro(success.data.viacep.logradouro)
					setNumero(success.data.viacep.numero)
					setComplemento(success.data.viacep.complemento)
					setBairro(success.data.viacep.bairro)
					setCidade(success.data.viacep.localidade)
					setEstado(success.data.viacep.uf)
					setErroCep(false)
				}
			}).catch(error => {
				console.log(error)
			})
		}
	}

	/* const validDoc = () => {
		axios.get(`${process.env.REACT_APP_URL_API}/Usuario/ver_doc/doc/${documento}`).then(success => {
			if(success.data.status){
				setDocumento('')
				setErroDoc(true)
			} else {
				setErroDoc(false)
			}
		}).catch(error => {
			console.log(error)
		})
	} */

	const signUp = () => {
		setWaiting(true)
		let user = {
			plano : plano,
			email : email,
			// carteira : carteira,
			indicante : login,
			ddi : ddi,
			nome : nome,
			paises : paises,
			usuario : usuario,
			conf_senha : confSenha,
			conf_seg : confSeg,
			whats : whats,
			nascimento : nascimento,
			documento : documento,
			cep : cep,
			logradouro : logradouro,
			numero : numero,
			complemento : complemento,
			bairro : bairro,
			cidade : cidade,
			estado : estado
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/Usuario/cadastrar_ajax`, user).then(success => {
			if(success.data.status){
				sessionStorage.removeItem('indicant')
				window.location = "/backoffice/login"
			} else {
				setErroCadastro(true)
			}
		}).catch(error => {
			console.log(error)
		})
	}

	return (
		<>
			<div className="wrapper">
				<div className="section-authentication-register d-flex align-items-center justify-content-center">
					<div className="row">
						<div className="mx-auto col-12 col-lg-10">
							<div className="card radius-15">
								<div className="text-center">
									<Link to="/">
										<div className="mt-3 text-center">
											<img src="/logos/logo.png" height="80" alt=""/>
											<h3 className="mt-4 font-weight-bold">{i18n.t('cadastrar_td')}</h3>
										</div>
									</Link>
								</div>
								<div className="text-center login-separater">
									<span>{i18n.t('indicado_por')} <strong>{login}</strong></span>
									<span>
										<Link to={`/${login}`} >{i18n.t('alterar_td')} {i18n.t('patrocinador_td')}</Link>
									</span>
									<hr/>
								</div>
								<div className="row no-gutters">
									<div className="col-lg-6">
										<div className="card-body">
											<div className="form-row">
												<div className="form-group col-md-6">
													<label>{i18n.t('plano_td')}</label>
													<FormControl as="select" value={plano} custom onChange={e => setPlano(e.target.value)}>
														{planos.map((opt, index) => {
															return <option key={index} value={opt.bn_id}>{opt.bn_nome}</option>
														})}
													</FormControl>
												</div>
												<div className="form-group col-md-6">
													<Form.Label>{i18n.t('usuario_td')}</Form.Label>
													{erroUsuario? <><br/><Badge variant="danger">{i18n.t('login_ja_tem')}</Badge></> : ''}
													<Form.Control type="text" placeholder={i18n.t('login')} onBlur={() => validNewUser()} autoComplete="off" value={usuario} onChange={e => setUsuario(e.target.value)} />
												</div>
											</div>
											<Form.Group>
												<Form.Label>{i18n.t('documento_td')}</Form.Label>
												{/* {erroDoc? <><br/><Badge variant="danger">{i18n.t('documento_repetido')}</Badge></> : ''} */}
												<Form.Control type="text" placeholder={`${i18n.t('documento_td')}`} autoComplete="off" value={documento} onChange={e => setDocumento(e.target.value)} />
											</Form.Group>
											{/* <Form.Group>
												<Form.Label>{i18n.t('carteira_td')} BTC</Form.Label>
												{erroCarteira? <><br/><Badge variant="danger">{i18n.t('msg_btc')}</Badge></> : ''}
												<Form.Control type="text" placeholder={`${i18n.t('carteira_td')} BTC`} autoComplete="off" value={carteira} onBlur={() => validCarteira()} onChange={e => setCarteira(e.target.value)} />
											</Form.Group> */}
											<Form.Group>
												<Form.Label>{i18n.t('nome_td')}</Form.Label>
												<Form.Control type="text" placeholder={i18n.t('nome_td')} autoComplete="off" onChange={e => setNome(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('nascimento_td')}</Form.Label>
												<Form.Control type="date" placeholder={i18n.t('nascimento_td')} autoComplete="off" onChange={e => setNascimento(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('email_td')}</Form.Label>
												{erroEmail? <><br/><Badge variant="danger">{i18n.t('email_td')} {i18n.t('invalido_td')}</Badge></> : ''}
												<Form.Control type="email" placeholder={i18n.t('email_td')} autoComplete="off" value={email} onBlur={() => validEmail()} onChange={e => setEmail(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('senha_td')}</Form.Label>
												<Form.Control type="password" placeholder={i18n.t('senha_td')} autoComplete="off" onChange={e => setSenha(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('confirmar_td')} {i18n.t('senha_td')}</Form.Label>
												{erroSenha? <Badge variant="danger">{i18n.t('senha_nao_confere')}</Badge> : ''}
												<Form.Control type="password" placeholder={`${i18n.t('confirmar_td')}  ${i18n.t('senha_td')}`} autoComplete="off" value={confSenha} onBlur={() => validSenha()} onChange={e => setConfSenha(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('senha_td')} {i18n.t('financeiro_td')}</Form.Label>
												<Form.Control type="password" placeholder={`${i18n.t('senha_td')} ${i18n.t('financeiro_td')}`} autoComplete="off" onChange={e => setSeg(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('confirmar_td')} {i18n.t('senha_td')} {i18n.t('financeiro_td')}</Form.Label>
												{erroSenhaF? <Badge variant="danger">{i18n.t('senhaf_nao_confere')}</Badge> : ''}
												<Form.Control type="password" placeholder={`${i18n.t('confirmar_td')} ${i18n.t('senha_td')} ${i18n.t('financeiro_td')}`} autoComplete="off" value={confSeg} onBlur={() => validSenhaF()} onChange={e => setConfSeg(e.target.value)} />
											</Form.Group>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="card-body">
											<Form.Label>{i18n.t('pais_td')}</Form.Label>
											<InputGroup>
												<FormControl as="select" value={paises} custom onChange={e => setPaises(e.target.value)}>
													{countries.map((opt, index) => {
														return <option key={index} value={opt.nome}>{opt.nome}</option>
													})}
												</FormControl>
											</InputGroup>
											<Form.Group>
												<Form.Label>{i18n.t('cep_td')}</Form.Label>
												{erroCep? <><br/><Badge variant="danger">{i18n.t('cep_invalido')}</Badge></> : ''}
												<Form.Control type="text" placeholder={i18n.t('cep_td')} onBlur={() => validCep()} autoComplete="off" value={cep} onChange={e => setCep(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('endereco_td')}</Form.Label>
												<Form.Control type="text" placeholder={`${i18n.t('endereco_td')}`} autoComplete="off" value={logradouro} onChange={e => setLogradouro(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('numero_td')}</Form.Label>
												<Form.Control type="number" placeholder={i18n.t('numero_td')} autoComplete="off" onChange={e => setNumero(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('complemento_td')}</Form.Label>
												<Form.Control type="text" placeholder={i18n.t('complemento_td')} autoComplete="off" value={complemento} onChange={e => setComplemento(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('bairro_td')}</Form.Label>
												<Form.Control type="text" placeholder={i18n.t('bairro_td')} autoComplete="off" value={bairro} onChange={e => setBairro(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('cidade_td')}</Form.Label>
												<Form.Control type="text" placeholder={i18n.t('cidade_td')} autoComplete="off" value={cidade} onChange={e => setCidade(e.target.value)} />
											</Form.Group>
											<Form.Group>
												<Form.Label>{i18n.t('estado_td')}</Form.Label>
												<Form.Control type="text" placeholder={i18n.t('estado_td')} autoComplete="off" value={estado} onChange={e => setEstado(e.target.value)} />
											</Form.Group>
											<div className="mt-4 form-row">
												<div className="form-group col-md-4">
													<Form.Label>{i18n.t('ddi_pais')}</Form.Label>
													<Form.Control type="number" placeholder="(ex.: +44)" autoComplete="off" onChange={e => setDdi(e.target.value)} />
												</div>
												<div className="form-group col-md-8">
													<Form.Label>{i18n.t('telefone_td')}</Form.Label>
													<Form.Control type="text" placeholder={i18n.t('telefone_td')} autoComplete="off" onChange={e => setWhats(e.target.value)} />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row no-gutters">
									<div className="col-md-12">
										<div className="card-body">
											<hr/>
											<div className="row">
												<div className="col-md-6">
													<div className="custom-control custom-checkbox">
														<Form.Check.Label><Form.Check.Input type={'checkbox'} onChange={e => setAceite(e.target.checked)}/>{i18n.t('aceite_td')} </Form.Check.Label> <button className="btn btn-light btn-sm" on onClick={() => setModalTermShow(true)}>{i18n.t('terms_td')}</button>
													</div>
												</div>
												<div className="col-md-6">
													<div className="btn-group w-100">
														{waiting?
															<h4 className="text-center"><Spinner animation="border" variant="primary" /> We are registering. Please wait...</h4>
														:
															<>
																{erroCadastro ? <Alert variant="danger">{i18n.t('erro_td')}</Alert> :''}

																<Button className="btn btn-light btn-block" disabled={ !plano | !email | !documento | !login | !ddi | !nome | !nascimento | !paises | !usuario | !confSenha | !confSeg | !whats | !cep | !logradouro | !cidade | !estado | !aceite } onClick={() => signUp()} > {i18n.t('cadastrar_td')} </Button>
																
																<button type="button" className="btn btn-light" disabled={ !plano | !email | !documento | !login | !ddi | !nome | !nascimento | !paises | !usuario | !confSenha | !confSeg | !whats | !cep | !logradouro | !cidade | !estado | !aceite } onClick={() => signUp()} ><i className="lni lni-arrow-right"></i>
																</button>
															</>
														}
													</div>
												</div>
											</div>
											<div className="mt-4 text-center">
												<p className="mb-0">
													<Link to="/backoffice/login">{i18n.t('cadastrado_td')}</Link>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalTermShow ?
				<ModalFeature
					size="lg"
					show={modalTermShow}
					onHide={() => setModalTermShow(false)}
				>
					<iframe src={`/docs/contrato_particular_de_filiacao_pix_mania.pdf`} title="app" frameBorder="0" style={{width:'100%',height:'90vh'}}></iframe>
				</ModalFeature>
			: ''}
		</>
	)
}