import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Col, Row } from 'react-bootstrap'
import { i18n } from '../../Components/Translates/i18n'
import DynamicTree  from 'react-dynamic-animated-tree'

export default function Linear() {
	// const token = process.env.REACT_APP_USER_TOKEN;
	const token = sessionStorage.getItem('token');
	
	const [treeData, setTreeData] = useState([])
	const [network, setNetwork] = useState([])
	
	useEffect(() => {
		const getLinear = () => {
			window.scrollTo(0, 0)
			axios.get(`${process.env.REACT_APP_URL_API}/Indicados/Indiretos/token/${token}`).then(success => {
				setTreeData(success.data.res)
				setNetwork(success.data)
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})
			document.title = `${i18n.t('linear_td')} | ${process.env.REACT_APP_NAME}`
		}
		getLinear()
	}, [token]);
	
	return (
		<Container>
			<Row>
				<Col xl="12">
					<div className="card radius-15">
						<div className="pb-0 border-0 card-header d-sm-flex d-block">
							<div className="pr-3 mb-3 mr-auto mb-sm-0">
								<h4>{i18n.t('linear_td')}</h4>
							</div>
						</div>
						<div className="card-body mb-2 py-1"  style={{backgroundColor: '#dadada'}}>
							<div className="row text-center">
								<div className="card p-2 m-2" style={{width: 'fit-content'}}>
									<h5 className="text-dark">{i18n.t('id_td')}: {network.dir}</h5>
								</div>
								<div className="card p-2 m-2" style={{width: 'fit-content'}}>
									<h5 className="text-dark">{i18n.t('ii_td')}: {network.indir}</h5>
								</div>
							</div>
						</div>
						<div className="card-body"  style={{backgroundColor: '#dadada'}}>
							<DynamicTree key="root" id="root" data={treeData} title={i18n.t('linear_td')}/>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	)
}