import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { isAuthenticated } from './Auth'
import { i18n } from '../Components/Translates/i18n'
import axios from 'axios'

export default function Forget() {
	document.title = `${i18n.t('esqueci_td')} | ${process.env.REACT_APP_NAME}`

	const [email, setEmail] = useState('')
	const [usuario, setUsuario] = useState('')
	const [alertSuccess, setAlertSuccess] = useState(false)
	const [alertError, setAlertError] = useState(false)

	const doForget = () => {
		axios.get(`${process.env.REACT_APP_URL_API}/Usuario/esqueceu_acesso/email/${email}/usuario/${usuario}`).then(success => {
			if(success.data.status){
				setAlertSuccess(true)
				setAlertError(false)
				setEmail('')
				setUsuario('')
			} else {
				console.log(success.data)
				setAlertSuccess(false)
				setAlertError(true)
			}
		}).catch(error => {
			console.log(error)
			setAlertSuccess(false)
			setAlertError(true)
		})
	}
	
	return (
		<>
			{!isAuthenticated() ? 
				<div className="wrapper">
					<div className="authentication-forgot d-flex align-items-center justify-content-center">
						<div className="shadow-lg card forgot-box">
							<div className="card-body p-md-5">
								<div className="text-center">
									<Link to="/">
										<img src="/logos/logo.png" width="140" alt="" />
									</Link>
									<h4 className="mt-5 text-white font-weight-bold">{i18n.t('esqueci_td')}?</h4>
									<hr />
								</div>
								{alertSuccess? <Alert variant="success">{i18n.t('esqueci_td_success')}</Alert> : ''}
								{alertError? <Alert variant="danger">{i18n.t('erro_td')}</Alert> : ''}
								
								<div className="mt-5 form-group">
									<label>{i18n.t('email_td')}</label>
									<input type="text" className="form-control form-control-lg radius-30" placeholder={i18n.t('email_td')} autoComplete="off" onChange={e => setEmail(e.target.value)}/>
								</div>
								<div className="form-group">
									<label>{i18n.t('usuario_td')}</label>
									<input type="text" className="form-control form-control-lg radius-30" placeholder={i18n.t('usuario_td')} autoComplete="off" onChange={e => setUsuario(e.target.value)} />
								</div>
								<button type="button" className="mt-5 btn btn-light btn-lg btn-block radius-30" onClick={e => doForget()} > {i18n.t('senha_up')}</button>
								<Link to="/backoffice/login" className="btn btn-link btn-block"><i className='mr-1 bx bx-arrow-back'></i> {i18n.t('cadastrado_td')}</Link>
							</div>
						</div>
					</div>
				</div>
			: <Redirect to={{pathname:'/backoffice/bo'}} />}
		</>
	)
}