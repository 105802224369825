const messages = {
	fr: {
		translations: {
			"pt_diferenca_td": "Points de différence précédente",
			"msg_saque_dia_td": "En raison du succès mondial et du volume constant de transactions dans le système, la fréquence des serveurs est quotidienne.",
			"sem_virgulas": "N'utilisez pas de virgules pour les décimales. Utilisez des points. Ex.: 99.50",
			"pagante_td": "Payant",
			"lider_lib": "Chef libéré",
			"lider_bloq": "Chef bloqué",
			"voucher_td": "Bon",
			"auth_td": "Authentification à deux facteurs",
			"auth_ok": "Votre compte est protégé par une vérification en deux étapes",
			"auth_ok_sub": "Chaque fois que vous vous connectez à votre compte, vous devrez utiliser votre mot de passe et un code de vérification unique généré par l'application Google Authenticator sur votre smartphone.",
			"no_auth_td": "Désactiver l'authentification à deux facteurs",
			"proteja_2fa": "Protégez votre compte avec la vérification en deux étapes",
			"proteja_2fa_sub": "Chaque fois que vous vous connectez à votre compte, vous devrez utiliser votre mot de passe et un code de vérification.",
			"addcamada_td": "Ajoutez une couche de sécurité supplémentaire",
			"addcamada_sub": "Saisissez votre mot de passe et le code de vérification unique généré par l'application Google Authenticator sur votre smartphone.",
			"keep_away_td": "Éloignez les méchants",
			"keep_away_sub": "Même si quelqu'un obtient votre mot de passe, ce ne sera pas suffisant pour vous connecter à votre compte.",
			"do_auth_td": "Activer l'authentification à deux facteurs",
			"siga_passos_td": "Suivez ces étapes pour activer l'authentification à deux facteurs",
			"step_1": "Ouvrez l'application Google Authenticator sur votre smartphone.",
			"step_2": "Cliquez sur le bouton Ajouter, puis sur Lire le code QR.",
			"step_3": "Pointez l'appareil photo de votre smartphone sur le code ci-dessous:",
			"dig_td": "Entrer le code de vérification",
			"code_verific_td": "Code de vérification",
			"code_verific_sub": "Saisissez le code qui apparaît sur votre smartphone. Si le temps s'écoule, entrez simplement le nouveau code qui est apparu.",
			"doverify_td": "Vérifier",
			"invalid_td": "Code de vérification invalide",
			"load_qr": "Chargement du code QR ...",
			"dig_login_td": "Saisissez le code de vérification généré dans l'application Google Authenticator",
			"materiais_td": "Matériel de support",
			"msg_sucesso": "Votre boleto a été généré avec succès et transmis à votre e-mail d'inscription",
			"msg_btc": "Portefeuille non valide",
			"msg_renovacao": "Vous avez atteint votre limite de gain, renouvelez votre forfait.",
			"info_endereco": "Remplissez tous les champs ci-dessous",
			"plano_titulo": "Parcours de carrière",
			"msg_carreira": "Votre nouveau cheminement de carrière a été mis à jour",
			"completo_percent": "Compléter",
			"pacotes_tb": "Liste des paquets",
			"view_qtd": "Quantité",
			"msg_pacote": "Forfait choisi avec succès",
			"msg_pacote_erro": "Impossible de terminer",
			"msg_pacote_vazio": "Vous devez choisir un forfait",
			"escolha_pacotes": "Choisissez un forfait",
			"usuario_td": "Utilisateur",
			"erro_logar": "Erreur de connexion",
			"fk_matriz": "Quartier général",
			"erro_logar_msg": "Nom d'utilisateur ou mot de passe invalide",
			"senha_td": "Mot de passe",
			"esqueci_td": "J'ai oublié le mot de passe",
			"acesso_td": "Accéder",
			"de_td": "dans",
			"seg_td": "Sécurité",
			"confirmar_td": "Confirmer",
			"email_td": "E-mail",
			"enviar_td": "Nous faire parvenir",
			"reenviar_td": "Renvoyer",
			"visualizar_td": "Regarder",
			"dias_td": "Jours",
			"horas_td": "Les heures",
			"minutos_td": "Minutes",
			"pais_td": "Pays",
			"paises_td": "Les pays",
			"estado_td": "Etat",
			"cidade_td": "Ville",
			"banco_de_dados": "Base de données",
			"meus_contatos": "Mes contacts",
			"banco_empresa": "Banque d'entreprise",
			"escolher_td": "Choisir",
			"todos_td": "Tout",
			"segunda_td": "Deuxième",
			"terca_td": "Troisième",
			"quarta_td": "Quatrième",
			"quinta_td": "Cinquième",
			"sexta_td": "vendredi",
			"sabado_td": "samedi",
			"domingo_td": "dimanche",
			"seg_sex": "lundi à vendredi",
			"sab_dom": "samedi et dimanche",
			"manha_td": "Matin",
			"tarde_td": "Soirée",
			"noite_td": "Nuit",
			"ativar_conta": "Connectez-vous à votre compte de messagerie et confirmez votre inscription",
			"ativar_zap": "Nous avons envoyé un message à WhatsApp:",
			"favor_zap": "Validez votre WhatsApp en cliquant sur le lien d'activation dans le message que nous vous avons envoyé.",
			"sim_td": "Ouais",
			"nao_td": "Non",
			"parabens_td": "Toutes nos félicitations",
			"sucesso_td": "Succès",
			"erro_td": "Erreur",
			"obrigado_td": "Merci",
			"cadastrar_td": "S'inscrire",
			"gravar_td": "Enregistrer",
			"salvar_td": "Sauver",
			"agora_td": "À présent",
			"postador_td": "Affiche",
			"texto_td": "Texte",
			"imagem_td": "Image",
			"ddi_pais": "Code postal",
			"cancelar_td": "Annuler",
			"continuar_td": "Continuar",
			"parar_td": "Continuez",
			"zap_td": "Whatsapp",
			"celular_td": "Cellule",
			"telefone_td": "Téléphone",
			"nome_td": "Nom",
			"endereco_td": "Adresse",
			"logradouro_td": "Lieu public",
			"numero_td": "Nombre",
			"numeros_td": "Nombres",
			"bairro_td": "Quartier",
			"comp_td": "Complément",
			"uf_td": "UF",
			"cep_td": "Code postal",
			"entrega_td": "Livraison",
			"data_td": "Date",
			"nasc_td": "Naissance",
			"plano_td": "Plat",
			"bem_vindo": "Sois le bienvenu",
			"efetuado_td": "Fabriqué avec",
			"status_td": "Statut",
			"upgrade_td": "Améliorer",
			"financeiro_td": "Financier",
			"principal_td": "Principale",
			"banco_td": "Banques",
			"bancarios_td": "Bancaire",
			"rede_td": "Réseau",
			"binario_td": "Binaire",
			"direto_td": "Direct",
			"linear_td": "Linéaire",
			"matriz_td": "Quartier général",
			"trans_td": "Transfert",
			"saldo_td": "Équilibre",
			"pg_saldo": "Payer avec solde",
			"liberado_td": "Publié",
			"bloqueado_td": "Bloqué",
			"pendente_td": "En attente",
			"dados_td": "Données",
			"pessoais_td": "Personnel",
			"nivel_td": "Niveau",
			"buscar_td": "Chercher",
			"moeda_td": "Pièce de monnaie",
			"dinheiro_td": "Espèces",
			"carreira_td": "Carrière",
			"subir_td": "Déplacer vers le haut",
			"foto_td": "Photographier",
			"msg_td": "Un message",
			"aqui_td": "Ici",
			"extrato_td": "Extrato",
			"sair_td": "Sortir",
			"logar_td": "Logar",
			"loja_td": "Magasin",
			"alterar_td": "Altérer",
			"perna_td": "Jambe",
			"direita_td": "Droit",
			"esquerda_td": "Gauche",
			"pedido_td": "Commander",
			"btc_td": "Bitcoin",
			"sacar_td": "Se rétracter",
			"taxa_td": "évaluer",
			"estorno": "Renversement",
			"link_de": "Lien d'inscription",
			"patrocinador_td": "Parrainer",
			"qualif_td": "Qualifié",
			"qualificacao_td": "Qualification",
			"renovar_td": "Renouveler",
			"ativo_td": "actif",
			"inativo_td": "Inactif",
			"comprar_td": "Acheter",
			"comprar_prod": "Acheter des produits",
			"titulo_msg": "ATTENTION",
			"erro_msg": "Impossible de générer la commande",
			"titulo_sucesso": "JOYEUX ANNIVERSAIRE",
			"sucesso_msg": "Commande générée avec succès",
			"pagar_td": "Payer",
			"realmente": "Vraiment envie",
			"valor_exato": "Payez exactement la même valeur ci-dessus",
			"com_td": "avec",
			"do_td": "de",
			"virgula_td": ",",
			"apagar_td": "Effacer",
			"limite_td": "Limite",
			"entrada_td": "Saisir",
			"saida_td": "Sortir",
			"total_td": "Total",
			"sub_total": "Total",
			"participe_td": "Être impliqué",
			"cota_td": "Actions",
			"divisao_lucro": "Partage des profits",
			"configura_td": "réglages",
			"aviso_td": "Avertissement",
			"carrinho_td": "Chariot",
			"vazio_td": "Ce champ ne peut pas être vide",
			"abrir_td": "Ouvert",
			"fechar_td": "Fermer",
			"cpf_td": "CPF",
			"menu_td": "Menu",
			"valor_td": "Évaluer",
			"pref_td": "Préférentiel",
			"auto_td": "Petite jambe",
			"escolha_td": "Choix",
			"disponivel_td": "Disponible",
			"pagamento_td": "Paiement",
			"boleto_td": "Billet",
			"opcao_td": "options",
			"pago_td": "Payé",
			"desc_td": "Description",
			"cred_td": "Crédit",
			"deb_td": "Débit",
			"tipo_td": "Taper",
			"privado_td": "Privé",
			"grupo_td": "Grouper",
			"tipo_conta": "Type de compte",
			"agencia_td": "Agence",
			"conta_td": "Compte",
			"dv_td": "Chiffre",
			"favorecido_td": "Favorisé",
			"corrente_td": "Chaîne",
			"poupanca_td": "Des économies",
			"update_td": "Mettre à jour",
			"escreva_td": "Écrivez",
			"reescreva_td": "Récrire",
			"ate_td": "jusqu'à",
			"nova_td": "Nouveau",
			"atual_td": "Actuel",
			"antiga_td": "Vieille",
			"carteira_td": "Portefeuille",
			"pix_td": "Clé PIX",
			"saque_td": "Se désister",
			"brinde_td": "Cadeau",
			"ativar_td": "Activer",
			"upline_td": "En amont",
			"frete_td": "Expédition",
			"passar_prazo_td": "Si vous dépassez la date limite, vous devez générer à nouveau",
			"potencial_td": "Potentiel de gains",
			"ficticio_vl": "Valeurs fictives",
			"ja_ganhei": "J'ai déjà gagné",
			"campanha_td": "Campagne",
			"adicionar_td": "Ajouter",
			"criar_td": "Créer",
			"base_empresa": "Liste des entreprises",
			"minha_base": "Ma liste",
			"ferramentas_td": "Outils",
			"licencas_td": "Licences",
			"serial_td": "En série",
			"videos_td": "Cours vidéo",
			"arquivo_td": "Déposer",
			"importar_td": "importer",
			"exportar_td": "Exportation",
			"bonus_td": "Prime",
			"investidor_td": "Investisseur",
			"simples_td": "Simple",
			"novamente_td": "De nouveau",
			"robo_td": "Robot",
			"segmentado_td": "Ciblé",
			"text_numeros": "Numéros ou groupes Lien séparés par; exemple: xxxxxx; xxxxxx; xxxxxx",
			"envia_100": "Envoyer 100 contacts de",
			"msg_moderacao": "La tête haute! Utilisez le robot avec parcimonie, si vous avez peur, n'utilisez pas votre puce personnelle.",
			"modo_td": "Mode",
			"msg_cad": "Pour continuer l'inscription, choisissez un forfait!",
			"emocao_td": "Chaque heure une nouvelle émotion!",
			"pack": "Mon colis",
			"data_ativa": "Date d'activation",
			"qualif_binario": "Qualification binaire",
			"plano_al": "Parcours de carrière",
			"qtd_rede": "Quantité de réseau",
			"sys_tb": "Dans le système",
			"saque_td2": "Retrait",
			"saque_td3": "Avec succès",
			"ate_hj": "Jusqu'à aujourd'hui",
			"entrada_et": "Saisir",
			"tb_usuarios": "Utilisateurs",
			"tb_usando_sys": "Dans le système",
			"plano_main": "Changer de plan",
			"plano_main2": "Je veux changer mon plan",
			"escolha_plano": "Choisissez un nouveau plan ci-dessous",
			"package_plano": "Paquet",
			"escolher_plano": "Choisir",
			"total_e": "Total restant",
			"total_d": "Droit total",
			"usu_rede": "Connexion utilisateur sous votre réseau",
			"voltar_top": "Retour au sommet",
			"sub_nivel": "Monter d'un niveau",
			"list_p": "Liste des points",
			"ped_b": "Commander",
			"ped_bs": "Demandes",
			"usu_b": "Utilisateur",
			"data_b": "Date",
			"pts_b": "Spots",
			"log_usu": "Entrez la connexion de l'utilisateur",
			"total_ee": "Gauche",
			"total_dd": "Droit",
			"limit_b": "Limite",
			"senha_up": "Changer le mot de passe",
			"senha_f": "Changer le mot de passe financier",
			"taxa": "Évaluer",
			"comprovante_msg": "Envoyer une preuve",
			"selecione_tb": "Sélectionnez une photo",
			"chamado": "Ouvrez un appel",
			"list_chamado": "Liste d'appels",
			"n_chamado": "Non.",
			"res_data": "Répondre",
			"ass_chamado": "Sujet",
			"respondido": "Répondu",
			"aberto": "Ouvert",
			"depart": "Choisissez un département",
			"finan": "Financier",
			"support": "Soutien",
			"repod": "Répondre",
			"succ_call": "Appel ouvert avec succès",
			"err_call": "Impossible d'ouvrir un ticket",
			"succ_chamado": "Votre appel a été envoyé avec succès",
			"err_chamado": "Il n'a pas été possible d'envoyer votre message",
			"atencao_td": "La tête haute",
			"senha_valid": "Mot de passe invalide, vérifiez vos données et réessayez!",
			"success_senhaf": "Mot de passe confirmé avec succès!",
			"carteira_td_succss": "Portefeuille enregistré avec succès, vérifiez votre courrier électronique à activer!",
			"pix_td_succss": "PIX a rappelé avec succès, vérifiez votre email pour activer!",
			"saque_email_td": "Dessin demandé, vérifiez votre courrier électronique et cliquez sur le lien pour autoriser!",
			"msg_wallet_email": "Pour votre meilleure sécurité, des données pour le paiement de cette demande ",
			"msg_wallet_email2": "ont été envoyés à email ",
			"msg_wallet_email3": "Veuillez consulter le numéro de commande et la date limite de paiement rapportée dans le courrier électronique.",
			"carteira_td_erro": "Nous n'avons pas pu enregistrer votre carrière, vérifiez vos coordonnées et réessayez!",
			"cadastre_carteira": "Enregistrez un portefeuille BTC ou une clé PIX pour effectuer des retraits",
			"msg_btc_vazio": "Remplissez le champ Bitcoin",
			"sacar_api_td": "API de paiement",
			"limite_80": "Vous avez atteint plus de 80% de votre limite et une demande de renouvellement a été automatiquement générée. Payez cette commande et évitez de bloquer vos Cashback.",

			"cred_ind_direta": "Crédit de parrainage direct",
			"id_td": "Indication directe",
			"ii_td": "Indication Indirecte",
			"cred_ind_indireta": "BONUS D'ÉQUIPE",
			"cred_mensalidade": "UNILEVEL",
			"debt_td": "Dette",
			"cred_binario": "Crédit binaire",
			"transf_debito": "Virement par débit",
			"transf_credito": "Transfert de crédit",
			"ped_retirada": "Demande de retrait",
			"extorno_td": "Retrait de retour",
			"tx_adm_deb": "Administrer la taxe",
			"tx_adm_cred": "Administrer la taxe",
			"cred_saldo_disp": "Solde créditeur disponible",
			"deb_de_trader": "Dette du commerçant",
			"ajust_limite": "Ajustement du seuil",
			"cred_pontos": "Points de crédit",
			"deb_saldo_disp": "Solde débiteur disponible",
			"pl_carreira": "Parcours de carrière",
			"ganh_diario": "CASHBACK",
			"saq_duplicado": "Retrait en double",
			"ajust_saldo": "Ajustement de l'équilibre",
			"tx_retirada": "frais de retrait",
			"cred_pagar_com_saldo": "Crédit Pay avec solde",
			"cred_upgrade": "Crédit UpGrade",
			"limite_excedido": "Dette limite dépassée",
			"cred_renovacao": "Renouvellement de crédit",
			"deb_saldo": "Solde débiteur",
			"doacao_td": "Don",
			"doa_men_receb": "Don mensuel reçu sur la plateforme",
			"bonus_saldo_trader": "Bonus de solde du commerçant",
			"cred_recb_trader": "Trader recevant des crédits",
			"deb_val_indev": "Montant indu de la dette",
			"cred_game_donate": "Jeu Donate Credit",
			"deb_game_donate": "Jeu de débit Faire un don",

			"ser_investidor": "Pour être un investisseur sur notre plate-forme, vous devez être référé par quelqu'un.",
			"indicante_td": "Qui est votre indique?",
			"escreva_indicante": "Écrivez le nom d'utilisateur de votre indicant",
			"erro_user_msg": "Erreur de rencontre utilisateur!",
			"aceite_td": "J'accepte",
			"terms_td": "termes et conditions",
			"indicado_por": "Vous êtes indiqué par:",
			"cadastrado_td": "Déjà enregistré? Cliquez ici et connectez-vous!",
			"ainda_não_cadastrado": "Pas encore enregistré?",
			"senha_nao_confere": "Le mot de passe et la confirmation doivent être égaux",
			"senhaf_nao_confere": "Le mot de passe financier et la confirmation doivent être égaux",
			"login_ja_tem": "Ce login existe déjà!S'il vous plaît choisissez un autre!",
			"invalido_td": "Invalide",
			"esqueci_td_success": "Le mot de passe a changé avec succès, vérifiez votre courrier électronique!",
			"empty_td": "Vider",
			"objetivo_td": "Objectif",
			"info_pessoal": "Renseignements Personnels",

			"semsaldo_td": "Solde insuffisant",
			"pacotemenor_td":"Ne peut prendre que jusqu'à la valeur de votre colis par jour",
			"body_mensalidade":"Votre UNILEVEL a expiré et un nouvel ordre de paiement a été généré, veuillez payer l'ordre et recevoir à nouveau vos Cashback.",
			"body_dia_primeiro":"Faites votre activation mensuelle et garantissez votre carte PIX MANIA activée ainsi que votre bonus UNILEVEL.",

			"complemento_td":"Complément",
			"nascimento_td":"Date de naissance",
			"documento_td":"Passeport",
			"documento_repetido":"Document déjà inscrit",
			"saque_minimo":"Retrait minimum",
			"aleatoria_td":"Aléatoire",
			"forma_td":"Forme de réception",
			"enviar_link":"Envoyer une liaison vidéo",
			"url_video":"Entrez l'URL vidéo",
			"link_enviado":"Lien vidéo envoyé avec succès!",
			"link_nao_enviado":"Lien vidéo non envoyé!",
			"utilizado_td":"Utilisé",
			"recusado_td":"Refusé"
		}
	}
}

export { messages }