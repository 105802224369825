import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import NumberFormat from 'react-number-format';

export default function CardStatus (props){
	return (
		<>
			<div className="card radius-15">
				<div className="text-center card-body">
					<div className="widgets-icons mx-auto rounded-circle"><i className={`fa fa-${props.icon} text-${props.bg}`}></i></div>
					<h4 className="mb-0 font-weight-bold mt-3 text-white">{props.money ? <NumberFormat value={props.valor} displayType={'text'} thousandSeparator={true} prefix={props.money}/> : props.valor}</h4>
					<p className="mb-0 text-white">{props.nome} {props.subvalor}</p>
					{props.perc ? 
						<>
							<ProgressBar variant={props.bg} animated now={props.perc} style={{height: '5px'}}/>
							<small>{props.small}</small>
						</>
					: <br/>}
				</div>
			</div>
		</>
	)
}
