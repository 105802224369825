import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { VectorMap } from 'react-jvectormap'

import { Alert, Row, Col, Button, InputGroup, FormControl, Table, Image } from 'react-bootstrap';
import Modals from '../../Components/SubComponents/Modals'
import ModalFeature from '../../Components/SubComponents/ModalFeature'
import * as Fa from 'react-icons/fa'
import { PieChart } from 'react-minimal-pie-chart';

import CardStatus from '../../Components/SubComponents/CardStatus'

import { i18n } from '../../Components/Translates/i18n'

export default function Bo() {
	const token = sessionStorage.getItem('token')
	
	const [usuarioy, setUsuarioy] = useState([])
	const [pais, setPais] = useState([])
	const [totaisPorPais, setTotaisPorPais] = useState([])
	const [linkDeCadastro, setLinkDeCadastro] = useState('')
	const [dados, setDados] = useState([])
	const [diretos, setDiretos] = useState(0)
	const [usuariox, setUsuariox] = useState([])
	// const [money, setMoney] = useState([])
	const [totalUsu, setTotalUsu] = useState('')
	const [latlng, setLatlng] = useState([])
	const [bloqueio, setBloqueio] = useState('')
	const [planAlterar, setPlanAlterar] = useState([])
	const [selectPlan, setSelectPlan] = useState('')
	const [renew, setRenew] = useState(false)
	const [ativaAlterarPlano, setAtivaAlterarPlano] = useState(false)
	const [cotas, setCotas] = useState([])
	const [alertMensalidade, setAlertMensalidade] = useState(false)
	const [alertDiaPrimeiro, setAlertDiaPrimeiro] = useState(false)
	const [alertoitenta, setAlertoitenta] = useState(false)
	const [nextCareer, setNextCareer] = useState([])
	const [upgradeAlertShow, setUpgradeAlertShow] = useState(false);
	const [noUpgradeAlertShow, setNoUpgradeAlertShow] = useState(false);
	const [listPlanos, setListPlanos] = useState([])
	const [selectUpgradePlan, setSelectUpgradePlan] = useState('')
	const [legCheck, setLegCheck] = useState('e')
	const [pernaAlertShow, setPernaAlertShow] = useState(false)
	const [dataPag, setDataPag] = useState('')

	const [fileDados, setFileDados] = useState([])
	const [embed, setEmbed] = useState([])
	
	const [pieData, setPieData] = useState([])

	const [copySuccess, setCopySuccess] = useState('');
	const [copyFileSuccess, setCopyFileSuccess] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const [modalFileShow, setModalFileShow] = useState(false);
	
	useEffect(() => {
		const getHome = () => {
			document.title = `${i18n.t('principal_td')} | ${process.env.REACT_APP_NAME}`
			window.scrollTo(0, 0)
			axios.get(`${process.env.REACT_APP_URL_API}/Bo/main/token/${token}`).then(success => {
				setPais(success.data.pais)
				setTotaisPorPais(success.data.totaisPorPais)
				setUsuarioy(success.data.usuarioy)
				setLinkDeCadastro(`${window.location.protocol}//${window.location.host}/${success.data.usuarioy.usu_usuario}`)
				let latLng = []
				success.data.latlng.map(e => {
					return (
						latLng.push({
							latLng: [e.latitude, e.longitude],
							name: e.usu_pais
						})
					)
				})
				setLatlng(latLng)
				// setMoney(success.data.money)
				setTotalUsu(success.data.total_usu)
				setNextCareer(success.data.nextCareer)
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})
			
			axios.get(`${process.env.REACT_APP_URL_API}/Bo/home/token/${token}`).then(success => {
				setDados(success.data.dados)
				setDiretos(success.data.diretos)
				setUsuariox(success.data.usuariox)
				setBloqueio(success.data.bloqueio)
				setPlanAlterar(success.data.planToAlter)
				setAtivaAlterarPlano(success.data.ativaAlterarPlano)
				setRenew(success.data.renew)
				setCotas(success.data.cotas[0])
				let cotasl = success.data.cotas[0]
				setPieData([
					{
						title: i18n.t('completo_percent'),
						value: parseFloat(cotasl?cotasl.ct_valor:0),
						percent: parseFloat(cotasl?(cotasl.ct_valor/cotasl.ct_limite)*100:0).toFixed(0),
						color: "#fff",
					},
					{
						title: i18n.t('limite_td'), 
						value: parseFloat(cotasl?cotasl.ct_limite:10),
						percent: false,
						color: "rgba(255, 255, 255, 0.52)",
					}
				])
				setAlertoitenta(success.data.alertoitenta)
				setAlertMensalidade(success.data.alertMensalidade)
				setAlertDiaPrimeiro(success.data.alertDiaPrimeiro)
				setListPlanos(success.data.selectFkBonus)
				setLegCheck(success.data.usuariox.pref)
				setDataPag(success.data.data_pag)
				
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})

			axios.get(`${process.env.REACT_APP_URL_API}/Arquivos/getFeature/token/${token}`).then(success => {
				if(success.data.status){
					if(sessionStorage.getItem('modalFile')){
						setModalFileShow(false)
					} else {
						setFileDados(success.data.fileDados)
						let emb = success.data.fileDados.a_arquivo.split('?v=') 
						setEmbed(emb)
						setModalFileShow(true)
					}
				} else {
					console.log(success.data)
				}
			}).catch(error => {
				console.log(error)
				/* sessionStorage.removeItem('token')
				let local = window.location
				window.location = local */
			})
		}
		getHome()
	}, [token]);
	
	function copyLinkToClipboard(e) {
		const copyLink = document.getElementById('copyLink');
		copyLink.select();
		document.execCommand('copy');
		e.target.focus();
		setCopySuccess('Copied!');
	};

	const classPlan = function(data) {
		for(let i = 1; i < 20; i++){
			let idFor = document.getElementById('card'+i)
			if(idFor){
				idFor.classList.remove("bg-success");
				idFor.classList.add("bg-primary");
			}
		}
		document.getElementById('card'+data).classList.remove("bg-primary");
		document.getElementById('card'+data).classList.add("bg-success");
		setSelectPlan(data);
	}

	const alterPlan = function () {
		axios.post(`${process.env.REACT_APP_URL_API}/Bo/mudar_plano/token/${token}`,selectPlan).then(success => {
			if (success.data.status) {
				window.location.href = "/backoffice/order";
			} else {
				alert(i18n.t('erro_td')+' '+i18n.t('plano_main'))
			}
		}).catch(error => {
			console.log(error)
			alert(i18n.t('erro_td')+' '+i18n.t('plano_main'))
		})
	}

	const newPlan = function () {
		axios.post(`${process.env.REACT_APP_URL_API}/Bo/upgradePlano/token/${token}`,selectUpgradePlan).then(success => {
			if (success.data.status) {
				setUpgradeAlertShow(true);
				setNoUpgradeAlertShow(false);
			} else {
				setUpgradeAlertShow(false);
				setNoUpgradeAlertShow(true);
			}
		}).catch(error => {
			console.log(error)
			setUpgradeAlertShow(false);
			setNoUpgradeAlertShow(true);
		})
	}
	
	
	function copyFileToClipboard(e) {
		const copyLink = document.getElementById('copyFile');
		copyLink.select();
		document.execCommand('copy');
		e.target.focus();
		setCopyFileSuccess('Copied!');
	};

	const selectPerna = function (data) {
		let post = JSON.stringify({perna: data});

		axios.post(`${process.env.REACT_APP_URL_API}/Rede/perna/token/${token}`,post).then(success => {
			if (success.data.status) {
				setLegCheck(success.data.pref);
				setPernaAlertShow(true);
			}
		}).catch(error => {
			console.log(error)
		})
	}
	return (
		<>
		<section className="section">
			<div className="container-fluid">
				<Row className="pt-2">
					<Col md="8">
						<div className="p-2 card radius-15">
							<InputGroup className="my-1">
								<InputGroup.Prepend>
									<Button variant="secondary" onClick={copyLinkToClipboard}>{i18n.t('link_de')}:</Button>
								</InputGroup.Prepend>
								<FormControl defaultValue={linkDeCadastro} id="copyLink" readOnly onClick={copyLinkToClipboard}/>
								<InputGroup.Append>
									<Button variant="secondary" onClick={copyLinkToClipboard}><Fa.FaCopy /> {copySuccess}</Button>
								</InputGroup.Append>
							</InputGroup>
							{upgradeAlertShow ? 
								<Alert variant="success" onClose={() => setUpgradeAlertShow(false)} dismissible>
									<strong>{i18n.t('parabens_td')}!</strong> {i18n.t('pedido_td')} {i18n.t('efetuado_td')} {i18n.t('sucesso_td')}. <Link to="/backoffice/order"><Button variant="success" size="sm">{i18n.t('pedido_td')}</Button></Link>
								</Alert>
							:''}
							{noUpgradeAlertShow ? 
								<Alert variant="danger" onClose={() => setNoUpgradeAlertShow(false)} dismissible>
									<strong>{i18n.t('erro_td')}!</strong> {i18n.t('pedido_td')} {i18n.t('pendente_td')}. <Link to="/backoffice/order"><Button variant="danger" size="sm">{i18n.t('pedido_td')}</Button></Link>
								</Alert>
							:''}
							{usuariox.alterar && ativaAlterarPlano ?
								<>
									<Button variant="primary my-1" block onClick={() => setModalShow(true)}>{i18n.t('plano_main2')}</Button>
								</>
							: 
								<InputGroup className="my-1"> 
									<InputGroup.Prepend>
										<Button variant="secondary">UPGRADE:</Button>
									</InputGroup.Prepend>
									<FormControl as="select" value={selectUpgradePlan} custom onChange={e => setSelectUpgradePlan(e.target.value)}>
										<option>-- {i18n.t('escolha_td')} {i18n.t('plano_td')} --</option>
										{listPlanos.map((opt, index) => {
											return <option key={index} value={opt.bn_id}>{`${opt.bn_nome} $: ${opt.carac_valor}`}</option>
										})}
									</FormControl>
									<InputGroup.Append>
										<Button variant="primary" onClick={() => newPlan()}>Upgrade</Button>
									</InputGroup.Append>
								</InputGroup>
							}
						</div>
					</Col>
					<Col md="4" className="text-center">
						<div className="p-2 card radius-15">
							<div className="pb-0 border-0 card-header d-sm-flex d-block">
								<div className="pr-3 mb-3 mr-auto mb-sm-0">
									<h4 className="text-light fs-20">{i18n.t('support')} {process.env.REACT_APP_NAME}</h4>
								</div>
							</div>
							<a href="https://wa.me/message/VBLQDGSGJ53FN1" target="_blank" rel="noopener noreferrer" className="my-1 btn btn-secondary"><Fa.FaWhatsapp/> {i18n.t('support')} {process.env.REACT_APP_NAME}</a>
							{/* <a href="https://wa.me/message/XHDAYRAAMU76E1" target="_blank" rel="noopener noreferrer" className="my-1 btn btn-secondary"><Fa.FaWhatsapp/> {i18n.t('support')} Pix Mania 2</a>
							<a href="https://wa.me/message/QYR45UKUQIMUG1" target="_blank" rel="noopener noreferrer" className="my-1 btn btn-secondary"><Fa.FaWhatsapp/>  {i18n.t('support')} Pix Mania 3</a> */}
						</div>
					</Col>
				</Row>

				<Row>
					<Col md='7'>
						<Row>
							<Col md="4">
								<CardStatus 
								bg="info"
								valor={usuarioy.ca_nome}
								nome={i18n.t('plano_al')}
								subvalor={`${usuarioy.menor} pts.`}
								perc={(usuarioy.menor / nextCareer.ca_pontos) *100}
								icon="star"
								imagem={usuarioy.ca_id}
								small={`${parseFloat((usuarioy.menor / nextCareer.ca_pontos) *100).toFixed(1)}% to ${nextCareer.ca_nome}`} />
							</Col>
							<Col md="4">
								<CardStatus
								bg="warning"
								valor={diretos}
								nome={i18n.t('total_td')}
								subvalor={i18n.t('direto_td')}
								icon="sitemap"
								/>
							</Col>
							<Col md="4">
								<CardStatus 
								bg="info"
								valor={dados.d_credito} 
								money="$ " 
								nome={i18n.t('saldo_td')}
								subvalor={i18n.t('Atual')}
								icon="usd" />
							</Col>
							<Col md="4">
								<CardStatus 
								bg={usuariox.alterar === false?'success':'danger'}
								valor={usuariox.alterar === false?i18n.t('sim_td'):i18n.t('nao_td')} 
								nome={i18n.t('ativo_td')}
								subvalor={i18n.t('sys_tb')}
								icon={usuariox.alterar === false?'thumbs-o-up':'thumbs-o-down'} />
							</Col>
							<Col md="8">
								<div className="card radius-15">
									<div className="text-center card-body">
										<h4>{i18n.t('plano_titulo')} - {i18n.t('pts_b')}</h4>
										<Row>
											<Col xs="6">
												<div className="card">
													{i18n.t('pts_b')} {i18n.t('esquerda_td')}: {dados.d_equipe_e}
												</div>
											</Col>
											<Col xs="6">
												<div className="card">
													{i18n.t('pts_b')} {i18n.t('direita_td')}: {dados.d_equipe_d}
												</div>
											</Col>
										</Row>
										{pernaAlertShow ?
											<Alert variant="success" onClose={() => setPernaAlertShow(false)} dismissible>
												<strong>{i18n.t('parabens_td')}!</strong> {legCheck === 'e'?i18n.t('esquerda_td'):i18n.t('direita_td')} {i18n.t('pref_td')}.
											</Alert>
										:''}
										<Row>
											<Col xs="6">
												<Button variant={legCheck === 'e'?'success':'dark'} block onClick={() => selectPerna('e')} id="pernae">{i18n.t('esquerda_td')}</Button>
											</Col>
											<Col xs="6">
												<Button variant={legCheck === 'd'?'success':'dark'} block onClick={() => selectPerna('d')} id="pernad">{i18n.t('direita_td')}</Button>
											</Col>
										</Row>
									</div>
								</div>
							</Col>
							{/* <Col md="4">
								<CardStatus
								bg="success"
								valor={money.entrada}
								money="$ "
								nome={i18n.t('entrada_et')}
								subvalor={i18n.t('ate_hj')}
								icon="money" />
							</Col>
							<Col md="4">
								<CardStatus
								bg="primary"
								valor={totalUsu}
								nome={i18n.t('tb_usuarios')}
								subvalor={i18n.t('tb_usando_sys')}
								icon="users" />
							</Col> */}
						</Row>
					</Col>
					<Col md='5'>
						{alertMensalidade ?
							<Alert variant="danger">
								<strong>{i18n.t('body_mensalidade')}</strong><br/>
								<Link to="/backoffice/order"><Button variant="danger">{i18n.t('pedido_td')}</Button></Link>
							</Alert>
						: ''}
						{alertDiaPrimeiro ?
							<Alert variant="warning">
								<strong>{i18n.t('body_dia_primeiro')}</strong><br/>
								<Link to="/backoffice/order"><Button variant="warning">{i18n.t('pedido_td')}</Button></Link>
							</Alert>
						: ''}
						{alertoitenta ?
							<Alert variant="warning">
								<strong>{i18n.t('limite_80')}</strong><br/>
								<Link to="/backoffice/order"><Button variant="warning">{i18n.t('pedido_td')}</Button></Link>
							</Alert>
						: ''}
						<div className="card radius-15">
							<div className="pb-0 border-0 card-header d-sm-flex d-block">
								<div className="pr-3 mb-3 mr-auto mb-sm-0">
									<h4 className="text-light fs-20">{i18n.t('objetivo_td')}</h4>
								</div>
							</div>
							<div className="card-body">
								{pieData?
									<PieChart 
										data={pieData}
										style={{ maxHeight: '260px', margin: '0px auto 5px auto' }}
										label={({ dataEntry }) => dataEntry.percent?`${dataEntry.percent}%`:''}
										labelStyle={{
											textShadow: '0px 0px 2px #fff'
										}}
										labelPosition='50'
									/>
								:''}
								<h5>{i18n.t('valor_td')} {cotas? new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(cotas.ct_valor):0} ({i18n.t('limite_td')} {cotas?new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(cotas.ct_limite):0})</h5>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md="7">
						<div className="card radius-15">
							<div className="card-body">
								<div className="mb-0 d-lg-flex align-items-center">
									<div>
										<h5 className="mb-0">{pais} {i18n.t('paises_td')} - {i18n.t('pais_td')}: {usuarioy.usu_pais}</h5>
									</div>
								</div>
								<hr/>
								<div style={{width: '100%', height: 300}}>
									<VectorMap map={'world_mill'}
										backgroundColor= 'transparent'
										borderColor= '#818181'
										borderOpacity= {0.25}
										borderWidth= {1}
										zoomOnScroll= {true}
										color= '#009efb'
										regionStyle= {{
											initial: {
												fill: '#fff'
											}
										}}
										markerStyle= {{
											initial: {
												r: 9,
												'fill': '#fff',
												'fill-opacity': 1,
												'stroke': '#000',
												'stroke-width': 5,
												'stroke-opacity': 0.4
											},
										}}
										enableZoom= {true}
										hoverColor= '#009efb'
										markers= {latlng}
										hoverOpacity= {null}
										normalizeFunction= 'linear'
										scaleColors= {['#b6d6ff', '#005ace']}
										selectedColor= '#c9dfaf'
										selectedRegions= {[]}
										showTooltip= {true}
										containerStyle={{
											width: '100%',
											height: '100%'
										}}
										containerClassName="map"
									/>
								</div>
							</div>
						</div>
					</Col>
					<Col md="5">
						<div className="card radius-15">
							<div className="card-body">
								<div className="mb-0 d-lg-flex align-items-center">
									<div>
										<h5 className="mb-0">Total:</h5>
									</div>
									<div className="ml-auto">
										<h3 className="mb-0"><span className="font-14">{pais} {i18n.t('paises_td')}</span></h3>
									</div>
								</div>
								<hr/>
								<div className="dashboard-top-countries">
									<ul className="list-group list-group-flush" style={{height: 300, overflowY: 'scroll'}}>
										{totaisPorPais.map((ttpais, index) => 
											<li key={index} className="bg-transparent list-group-item d-flex align-items-center">
												<div className="media align-items-center">
													<div className="ml-2 media-body">
														<h6 className="mb-0">{ttpais.usu_pais}</h6>
													</div>
												</div>
												<div className="ml-auto">{ttpais.total * 5}</div>
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				
				{renew ?
					<Alert variant="danger">
						<h3 className="text-danger">{i18n.t('titulo_msg')}!</h3>
						<h4 className="text-danger">{bloqueio}</h4>
						<p>{i18n.t('msg_renovacao')}</p>
						<hr/>
						<Link to="/backoffice/order"><Button variant="danger">{i18n.t('renovar_td')}</Button></Link>
					</Alert>
				: ''}
				<Row>
					<Col>
						<div className="p-2 card radius-15">
							<h4>{i18n.t('dados_td')}</h4>
							<Table responsive striped bordered hover>
								<thead>
									<tr>
										<th>{i18n.t('pack')}</th>
										<th>{i18n.t('data_ativa')}</th>
										{/* <th>{i18n.t('qualif_binario')}</th> */}
										<th>{i18n.t('plano_al')}</th>
										{/* <th>{i18n.t('qtd_rede')}</th> */}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{usuariox.bn_nome}</td>
										<td>{dataPag?dataPag:''}</td>
										{/* <td>{usuariox.usu_data_qualif?`${i18n.t('sim_td')} - ${new Intl.DateTimeFormat('pt-BR').format(Date.parse(usuariox.usu_data_qualif))}`:i18n.t('nao_td')}</td> */}
										<td>{usuariox.ca_nome}</td>
										{/* <td>{totalUsu / 5}</td> */}
									</tr>
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
			</div>
			
		</section>

		<Modals
			size="lg"
			title={i18n.t('escolha_plano')}
			// contentClassName="bg-dark"
			show={modalShow}
			onHide={() => setModalShow(false)}
		>
			<Button variant="primary mb-3" size="lg" block onClick={() => alterPlan()}>{i18n.t('alterar_td')}</Button>
			<Row>
				{planAlterar.map((el, index) => {
					return (
						<Col md="4" key={index} onClick={() => classPlan(el.bn_id)}>
							<div className="p-2 text-center card bg-primary" id={`card${el.bn_id}`} style={{cursor: 'pointer'}}>
								<h4 className="m-1">{el.bn_nome}</h4>
								<h3 className="m-1">US$ {el.carac_valor}</h3>
							</div>
						</Col>
					)
				})}
			</Row>
			<Button variant="primary" size="lg" block onClick={() => alterPlan()}>{i18n.t('alterar_td')}</Button>
		</Modals>


		{modalFileShow ?
			<ModalFeature
				size="lg"
				show={modalFileShow}
				onHide={() => {
					sessionStorage.setItem('modalFile', 'hidden');
					setModalFileShow(false)
					}
				}
			>
				{!fileDados
				? ''
				: fileDados.a_tipo === 'video'
				? <video src={`/files/${fileDados.a_arquivo}`} controls autoplay style={{width:'100%', height:'60vh'}}></video>
				: fileDados.a_tipo === 'application'
				? <iframe src={`/files/${fileDados.a_arquivo}`} title="app" frameBorder="0" style={{width:'100%',height:'60vh'}}></iframe>
				: fileDados.a_tipo === 'youtube'
				? <>
					<iframe src={`https://www.youtube.com/embed/${embed[1]}`} title="yt" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{width:'100%', height:'60vh'}}></iframe>
					<div className="input-group">
						<input className="form-control" type="text" readOnly id={`copyFile`} value={fileDados.a_arquivo} />
						<div className="input-group-append">
							<button className="btn btn-secondary" onClick={copyFileToClipboard}><i className="fa fa-copy"></i> {copyFileSuccess?copyFileSuccess:'Copy'}</button>
						</div>
					</div>
					</>
				: <Image src={`/files/${fileDados.a_arquivo}`} className="img-fluid" />}
			</ModalFeature>
		: ''}
	</>
	)
}