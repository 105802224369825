import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Col, Row, Table, Form, InputGroup, FormControl, Badge } from 'react-bootstrap'
import { i18n } from '../../Components/Translates/i18n'
import Modals from '../../Components/SubComponents/Modals'

export default function Profile() {
	// const token = process.env.REACT_APP_USER_TOKEN;
	const token = sessionStorage.getItem('token');
	
	const [pessoais, setPessoais] = useState([])
	const [countries, setCountries] = useState([])
	
	const [cep, setCep] = useState('')
	const [logradouro, setLogradouro] = useState('')
	const [numero, setNumero] = useState('')
	const [complemento, setComplemento] = useState('')
	const [bairro, setBairro] = useState('')
	const [cidade, setCidade] = useState('')
	const [estado, setEstado] = useState('')
	const [paises, setPaises] = useState('')

	const [modalShow, setModalShow] = useState(false)
	
	useEffect(() => {
		const getPessoais = () => {
			window.scrollTo(0, 0)
			axios.get(`${process.env.REACT_APP_URL_API}/MeusDados/dadospessoais_ajax/token/${token}`).then(success => {
				setPessoais(success.data.dados)
				if(success.data.endereco){
					setCep(success.data.endereco.zip)
					setLogradouro(success.data.endereco.logradouro)
					setNumero(success.data.endereco.numero)
					setComplemento(success.data.endereco.complemento)
					setBairro(success.data.endereco.bairro)
					setCidade(success.data.endereco.cidade)
					setEstado(success.data.endereco.estado)
					setPaises(success.data.endereco.pais)
				}
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})
			
			axios.get(`${process.env.REACT_APP_URL_API}/Bo/getPlanos`).then(success => {
				setCountries(success.data.countries)
			})

			document.title = `${i18n.t('pessoais_td')} | ${process.env.REACT_APP_NAME}`
		}
		getPessoais()
	}, [token]);
	

	const [erroCep, setErroCep] = useState(false)

	const validCep = () => {
		if(paises === 'Brazil'){
			axios.get(`${process.env.REACT_APP_URL_API}/Usuario/ver_cep/cep/${cep}`).then(success => {
				if(!success.data.status){
					setErroCep(true)
				} else {
					setCep(success.data.viacep.cep)
					setLogradouro(success.data.viacep.logradouro)
					setNumero(success.data.viacep.numero)
					setComplemento(success.data.viacep.complemento)
					setBairro(success.data.viacep.bairro)
					setCidade(success.data.viacep.localidade)
					setEstado(success.data.viacep.uf)
					setErroCep(false)
				}
			}).catch(error => {
				console.log(error)
			})
		}
	}

	const sendAddress = () => {
		let post = {
			cep,
			logradouro,
			numero,
			complemento,
			bairro,
			cidade,
			estado,
			paises,
		}
		post = JSON.stringify(post)
		axios.post(`${process.env.REACT_APP_URL_API}/MeusDados/editAddress/token/${token}`, post).then(success => {
			setModalShow(false)
		}).catch(error => {
			console.log(error)
		})
	}

	return (
		<>
		<Row>
			<Col md="6">
				<div className="card radius-15">
					<div className="pb-0 border-0 card-header d-sm-flex d-block">
						<div className="pr-3 mb-3 mr-auto mb-sm-0">
							<h4>{i18n.t('dados_td')} {i18n.t('pessoais_td')}</h4>
						</div>
					</div>
					<div className="card-body">
						<Table responsive hover striped bordered >
							<tbody>
								<tr>
									<th>{i18n.t('nome_td')}</th>
									<td>{pessoais.usu_nome}</td>
								</tr>
								<tr>
									<th>{i18n.t('usuario_td')}</th>
									<td>{pessoais.usu_usuario}</td>
								</tr>
								<tr>
									<th>{i18n.t('email_td')}</th>
									<td>{pessoais.usu_email}</td>
								</tr>
								<tr>
									<th>Whatsapp</th>
									<td>{pessoais.usu_zap}</td>
								</tr>
							</tbody>	
						</Table>
					</div>
				</div>
			</Col>
			<Col md="6">
				<div className="card radius-15">
					<div className="pb-0 border-0 card-header d-sm-flex d-block">
						<div className="pr-3 mb-3 mr-auto mb-sm-0">
							<h4>{i18n.t('endereco_td')}</h4>
						</div>
						<div className="pr-3 mb-3 ml-auto mb-sm-0">
							<button className="btn btn-secondary btn-sm" onClick={() => setModalShow(true)}>
								<i className="fa fa-edit"></i> {i18n.t('alterar_td')}
							</button>
						</div>
					</div>
					<div className="card-body">
						<Table responsive hover striped bordered >
							<tbody>
								<tr>
									<th>{i18n.t('endereco_td')}</th>
									<td>{logradouro} {numero?`, ${numero}`:''}  {complemento?`, ${complemento}`:''}</td>
								</tr>
								<tr>
									<th>{i18n.t('bairro_td')}</th>
									<td>{bairro}</td>
								</tr>
								<tr>
									<th>{i18n.t('cidade_td')}/{i18n.t('estado_td')}/{i18n.t('pais_td')}</th>
									<td>{cidade}, {estado}, {paises}</td>
								</tr>
								<tr>
									<th>{i18n.t('cep_td')}</th>
									<td>{cep}</td>
								</tr>
							</tbody>	
						</Table>
					</div>
				</div>
			</Col>
		</Row>
		<Modals
			size="lg"
			title={`${i18n.t('alterar_td')} ${i18n.t('endereco_td')}`}
			// contentClassName="bg-dark"
			show={modalShow}
			onHide={() => setModalShow(false)}
		>
			<Row>
				<Col md="6">
					<Form.Label>{i18n.t('pais_td')}</Form.Label>
					<InputGroup>
						<FormControl as="select" value={paises} custom onChange={e => setPaises(e.target.value)}>
							{countries.map((opt, index) => {
								return <option key={index} value={opt.nome}>{opt.nome}</option>
							})}
						</FormControl>
					</InputGroup>
				</Col>
				<Col md="6">
					<Form.Group>
						<Form.Label>{i18n.t('cep_td')}</Form.Label>
						{erroCep? <><br/><Badge variant="danger">{i18n.t('cep_invalido')}</Badge></> : ''}
						<Form.Control type="text" placeholder={i18n.t('cep_td')} onBlur={() => validCep()} autoComplete="off" value={cep} onChange={e => setCep(e.target.value)} />
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group>
						<Form.Label>{i18n.t('endereco_td')}</Form.Label>
						<Form.Control type="text" placeholder={`${i18n.t('endereco_td')}`} autoComplete="off" value={logradouro} onChange={e => setLogradouro(e.target.value)} />
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group>
						<Form.Label>{i18n.t('numero_td')}</Form.Label>
						<Form.Control type="number" value={numero} placeholder={i18n.t('numero_td')} autoComplete="off" onChange={e => setNumero(e.target.value)} />
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group>
						<Form.Label>{i18n.t('complemento_td')}</Form.Label>
						<Form.Control type="text" placeholder={i18n.t('complemento_td')} autoComplete="off" value={complemento} onChange={e => setComplemento(e.target.value)} />
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group>
						<Form.Label>{i18n.t('bairro_td')}</Form.Label>
						<Form.Control type="text" placeholder={i18n.t('bairro_td')} autoComplete="off" value={bairro} onChange={e => setBairro(e.target.value)} />
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group>
						<Form.Label>{i18n.t('cidade_td')}</Form.Label>
						<Form.Control type="text" placeholder={i18n.t('cidade_td')} autoComplete="off" value={cidade} onChange={e => setCidade(e.target.value)} />
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group>
						<Form.Label>{i18n.t('estado_td')}</Form.Label>
						<Form.Control type="text" placeholder={i18n.t('estado_td')} autoComplete="off" value={estado} onChange={e => setEstado(e.target.value)} />
					</Form.Group>
				</Col>
				<Col md="12">
					<button className="btn btn-primary" onClick={() => sendAddress()}>
						{i18n.t('enviar_td')}
					</button>
				</Col>
			</Row>
		</Modals>
		</>
	)
}