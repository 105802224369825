import React from 'react'
import { Link } from "react-router-dom"

const SubMenu = (props) => {
	const showSubnav = () => {
		document.getElementsByClassName('mm-show')[0].classList.remove('mm-show')
		document.getElementsByClassName('mm-active')[0].classList.remove('mm-active')
		if(window.screen.availWidth < 1280){
			document.getElementsByClassName('wrapper')[0].classList.toggle('toggled')
		}
	}
	const closeMenu = () => {
		if(window.screen.availWidth < 1280){
			document.getElementsByClassName('wrapper')[0].classList.toggle('toggled')
		}
	}

	return (
		<>
			{props.item.subNav ? 
				<>
					<li key={props.btn}>
						<a href="true" onClick={e => e.preventDefault()} className="has-arrow" style={{cursor: 'pointer'}}>
							<div className="parent-icon">
								{props.item.icon}
							</div>
							<div className="menu-title">
								{props.item.title}
							</div>
						</a>
						<ul>
							{props.item.subNav.map((subItem, indice) => {
								return (
									<li key={indice} onClick={showSubnav}>
										<Link to={subItem.path}>
												{subItem.icon} {subItem.title}
										</Link>
									</li>
								)
							})}
						</ul>
					</li>
				</> 
				:
				<li onClick={closeMenu}>
					<Link to={props.item.path}>
						<div className="parent-icon">
							{props.item.icon}
						</div>
						<div className="menu-title">
							{props.item.title}
						</div>
					</Link>
				</li>
			}
		</>
	)
}

export default SubMenu