import React from 'react'
import { SidebarData } from './SidebarData'
import SubMenu from './SubMenu'
import * as Fa from 'react-icons/fa'

export default function Sidebar(props) {
	return (
		<>
			<div className="nav-container">
				<div className="mobile-topbar-header">
					<div className="">
						<img src="/logos/logo.png" height="60" alt="{process.env.REACT_APP_NAME}" />
					</div>
					<a href="true" onClick={e => e.preventDefault()} className="ml-auto toggle-btn"> <Fa.FaBars/> </a>
				</div>
				<nav className="topbar-nav">
					<ul className="metismenu" id="menu">
						{SidebarData.map((item, index) =>{
							return <SubMenu item={item} btn={index} key={index}/>
						})}
					</ul>
				</nav>
			</div>
		</>
	)
}