import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { i18n } from '../../Components/Translates/i18n'
import Modals from '../../Components/SubComponents/Modals'

export default function UpdatePix() {
	document.title = `${i18n.t('cadastrar_td')} PIX | ${process.env.REACT_APP_NAME}`

	const token = sessionStorage.getItem('token')

	const [alertSuccess, setAlertSuccess] = useState(false)
	const [alertError, setAlertError] = useState(false)
	const [alertErrorSenha, setAlertErrorSenha] = useState(false)

	const [pix, setPix] = useState([])
	const [seg, setSeg] = useState('')
	const [newPix, setNewPix] = useState('')
	const [tipo, setTipo] = useState('cpf')
	
	const [showModalEditPix, setShowModalEditPix] = useState(false)
	const [editPix, setEditPix] = useState('')

	useEffect(() => {
		const getPixView = () => {
			window.scrollTo(0, 0)
			axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/saque_bitcoin/token/${token}`).then(success => {
				setPix(success.data.meuspix)
			}).catch(error => {
				console.log(error)
				sessionStorage.removeItem('token')
				let local = window.location
				window.location = local
			})
		}
		getPixView()
	}, [token])

	const validPassword = () => {
		let user = {
			senha : seg
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/Usuario/valid_senhaf/token/${token}`, user).then(success => {
			if(!success.data.status){
				setSeg('')
				setAlertErrorSenha(true)
			} else {
				setAlertErrorSenha(false)
			}
		}).catch(error => {
			console.log(error)
			sessionStorage.removeItem('token')
			let local = window.location
			window.location = local
		})
	}

	const doRegPix = () => {
		let user = {
			senha : seg,
			pix : newPix,
			tipo
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/ContaCorrente/registro_pix/token/${token}`, user).then(success => {
			if(success.data.status){
				setNewPix('')
				setSeg('')
				setAlertError(false)
				setAlertSuccess(true)
				axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/saque_bitcoin/token/${token}`).then(success => {
					setPix(success.data.meuspix)
				})
			} else {
				setAlertSuccess(false)
				setAlertError(true)
			}
		}).catch(error => {
			console.log(error)
			sessionStorage.removeItem('token')
			let local = window.location
			window.location = local
		})
	}

	const doDeletePix = data => {
		let user = {
			senha : seg,
			id: data
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/ContaCorrente/delete_pix/token/${token}`, user).then(success => {
			if(success.data.status){
				setEditPix('')
				setSeg('')
				axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/saque_bitcoin/token/${token}`).then(success => {
					setPix(success.data.meuspix)
				})
				setShowModalEditPix(false)
			}
		}).catch(error => {
			console.log(error)
			sessionStorage.removeItem('token')
			let local = window.location
			window.location = local
		})
	}

	return (
		<>
			<Row>
				<Col>
					<div className="card radius-15">
						<div className="pb-0 border-0 card-header d-sm-flex d-block">
							<div className="pr-3 mb-3 mr-auto mb-sm-0">
								<h4>{i18n.t('cadastrar_td')} {i18n.t('pix_td')}</h4>
							</div>
						</div>
						<div className="card-body">
							<Row>
								<Col>
									<Form>
										{alertSuccess ?
											<Alert variant="success" onClose={() => setAlertSuccess(false)} dismissible><strong>{i18n.t('parabens_td')}!</strong> {i18n.t('pix_td_succss')}</Alert>
										: ''}
										{alertError ?
											<Alert variant="danger" onClose={() => setAlertError(false)} dismissible> <strong>{i18n.t('atencao_td')}!</strong> {i18n.t('pix_td_erro')}</Alert>
										: ''}
										{alertErrorSenha ?
											<Alert variant="danger" onClose={() => setAlertErrorSenha(false)} dismissible> <strong>{i18n.t('atencao_td')}!</strong> {i18n.t('senha_valid')}</Alert>
										: ''}
										<Row>
											<Col md="3">
												<Form.Group>
													<Form.Label>{i18n.t('senha_td')} {i18n.t('financeiro_td')}</Form.Label>
													<Form.Control type="password" placeholder={`${i18n.t('senha_td')} ${i18n.t('financeiro_td')}`} value={seg} autoComplete="off" onChange={e => setSeg(e.target.value)} onBlur={() => validPassword()}/>
												</Form.Group>
											</Col>
											<Col md="3">
												<Form.Group>
													<Form.Label>{i18n.t('pix_td')}</Form.Label>
													<Form.Control type="text" placeholder={i18n.t('pix_td')} value={newPix} autoComplete="off" onChange={e => setNewPix(e.target.value)} />
												</Form.Group>
											</Col>
											<Col md="3">
												<Form.Group>
													<Form.Label>{i18n.t('tipo_td')}</Form.Label>
													<Form.Control as="select" value={tipo} onChange={e => setTipo(e.target.value)}>
														<option value="cpf">CPF</option>
														<option value="telefone">{i18n.t('telefone_td')}</option>
														<option value="email">Email</option>
														<option value="aleatoria">{i18n.t('aleatoria_td')}</option>
													</Form.Control>
												</Form.Group>
											</Col>
											<Col md="3">
												<Form.Group>
													<Form.Label>{i18n.t('cadastrar_td')}</Form.Label><br/>
													<Button variant="success" disabled={!seg | !newPix } onClick={() => doRegPix()}>{i18n.t('cadastrar_td')}</Button>
												</Form.Group>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
			{pix.length > 0?
				<Row>
					<Col>
						<div className="card radius-15">
							<div className="pb-0 border-0 card-header d-sm-flex d-block">
								<div className="pr-3 mb-3 mr-auto mb-sm-0">
									<h4>{i18n.t('update_td')} {i18n.t('pix_td')}</h4>
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									{pix.map((el, index) => 
										<div className="col-md-3" key={index}>
											<div className="card">
												<div className="list-group">
													{
														el.fk_status === 0 ?
														<div className="list-group-item bg-warning text-dark d-flex justify-content-between"><span>{i18n.t('pendente_td').toUpperCase()}</span><button className="btn btn-default" onClick={() => {setEditPix(el.id); setShowModalEditPix(true)}}><i className="fa fa-trash"></i></button></div>

														:el.fk_status === 1 ?
														<div className="list-group-item bg-success d-flex justify-content-between"><span>{i18n.t('liberado_td').toUpperCase()}</span><button className="btn btn-default" onClick={() => {setEditPix(el.id); setShowModalEditPix(true)}}><i className="fa fa-trash"></i></button></div>

														:el.fk_status === 2 ?
														<div className="list-group-item bg-danger d-flex justify-content-between"><span>{i18n.t('bloqueado_td').toUpperCase()}</span><button className="btn btn-default" onClick={() => {setEditPix(el.id); setShowModalEditPix(true)}}><i className="fa fa-trash"></i></button></div>

														:''
													}
													<div className="list-group-item">{i18n.t('pix_td')}: {el.chave}</div>
													<div className="list-group-item">{i18n.t('tipo_td')}: {el.tipo}</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</Col>
				</Row>
			:''}
			<Modals
				size="lg"
				title={`${i18n.t('apagar_td')} ${i18n.t('pix_td')}`}
				show={showModalEditPix}
				onHide={() => setShowModalEditPix(false)}
			>
				{alertErrorSenha ?
					<Alert variant="danger" onClose={() => setAlertErrorSenha(false)} dismissible> <strong>{i18n.t('atencao_td')}!</strong> {i18n.t('senha_valid')}</Alert>
				: ''}
				<Form.Group>
					<Form.Label>{i18n.t('senha_td')} {i18n.t('financeiro_td')}</Form.Label>
					<Form.Control type="password" placeholder={`${i18n.t('senha_td')} ${i18n.t('financeiro_td')}`} value={seg} autoComplete="off" onChange={e => setSeg(e.target.value)} onBlur={() => validPassword()}/>
				</Form.Group>
				<Form.Group>
					<Form.Label>{i18n.t('apagar_td')}</Form.Label><br/>
					<Button variant="danger" disabled={!seg} onClick={() => doDeletePix(editPix)}>{i18n.t('apagar_td')}</Button>
				</Form.Group>
			</Modals>
		</>
	)
}