import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Link, useParams } from "react-router-dom"
import { Alert, Form, Badge, Button } from 'react-bootstrap'
import { i18n } from '../Components/Translates/i18n'

export default function Resfinpass (props) {
	let { email, token } = useParams();
	const [senha, setSenha] = useState('')
	const [confSenha, setConfSenha] = useState('')
	const [fkId, setFkId] = useState('')
	const [erroMsg, setErroMsg] = useState('')

	const [tokenValid, setTokenValid] = useState(false)

	const [erroSenha, setErroSenha] = useState(false)
	
	useEffect(() => {
		document.title = `${i18n.t('alterar_td')} ${i18n.t('senha_td')} ${i18n.t('de_td')} ${i18n.t('financeiro_td')} | ${process.env.REACT_APP_NAME}`
		
		const doSearch = () => {
			let senhaf = { email, token, senha, confSenha }
			senhaf = JSON.stringify(senhaf)
			axios.post(`${process.env.REACT_APP_URL_API}/Usuario/validTokenSenha/`,senhaf).then(success => {
				console.log(success.data)
				if(success.data.status){
					setTokenValid(true)
					setFkId(success.data.fkId)
				} else {
					setTokenValid(false)
					setErroMsg(success.data.error_msg)
				}
			}).catch(error => {
				console.log(error)
			})
		}
		doSearch()
	}, [confSenha, email, senha, token]);

	const validSenha = () => {
		if (confSenha !== senha){
			setConfSenha('')
			setErroSenha(true)
		} else {
			setErroSenha(false)
		}
	}

	const resetSenha = () => {
		let senhaf = { email, token, senha, confSenha, fkId }
		senhaf = JSON.stringify(senhaf)
		axios.post(`${process.env.REACT_APP_URL_API}/Usuario/alterFinancialPassword/`, senhaf).then(success => {
			console.log(success.data)
			if(success.data.status){
				alert('FINANCIAL PASSWORD UPDATED SUCCESSFULLY');
				window.location = "/backoffice/login"
			}
		}).catch(error => {
			console.log(error)
		})
	}
	
	return (
		<>
			<div className="wrapper">
				<div className="authentication-forgot d-flex align-items-center justify-content-center">
					<div className="shadow-lg card forgot-box">
						<div className="card-body p-md-5">
							<div className="text-center">
								<Link to="/">
									<img src="/logos/logo.png" width="140" alt="" />
								</Link>
								<h4 className="mt-5 text-white font-weight-bold">{`${i18n.t('alterar_td')} ${i18n.t('senha_td')} ${i18n.t('de_td')} ${i18n.t('financeiro_td')}`}</h4>
								<hr />
							</div>

							{tokenValid ?
								<>
									<div className="mt-5 form-group">
										<Form.Label>{i18n.t('senha_td')}</Form.Label>
										<Form.Control className="form-control-lg radius-30" type="password" placeholder={i18n.t('senha_td')} autoComplete="off" onChange={e => setSenha(e.target.value)} />
									</div>
									<div className="form-group">
										<Form.Label>{i18n.t('confirmar_td')} {i18n.t('senha_td')}</Form.Label>
										{erroSenha? <Badge variant="danger">{i18n.t('senha_nao_confere')}</Badge> : ''}
										<Form.Control className="form-control-lg radius-30" type="password" placeholder={`${i18n.t('confirmar_td')}  ${i18n.t('senha_td')}`} autoComplete="off" value={confSenha} onBlur={() => validSenha()} onChange={e => setConfSenha(e.target.value)} />
									</div>
									<Button className="mt-5 btn btn-light btn-lg btn-block radius-30" disabled={ !senha | !confSenha } onClick={() => resetSenha()} > Reset </Button>
								</>
							:<Alert variant="danger">{erroMsg}</Alert>}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}