const messages = {
	es: {
		translations: {
			"pt_diferenca_td": "Puntos de diferencia previa",
			"msg_saque_dia_td": "Debido al éxito mundial y al volumen constante de transacciones en el sistema, la frecuencia de retiro es diaria.",
			"sem_virgulas": "No utilice comas para decimales. Puntos de uso. Ex.: 99.50",
			"pagante_td": "Pago",
			"lider_lib": "Líder Liberado",
			"lider_bloq": "Líder Bloqueado",
			"voucher_td": "Vale",
			"auth_td": "Autenticación de dos factores",
			"auth_ok": "Su cuenta está protegida con la verificación en dos pasos",
			"auth_ok_sub": "Siempre que inicie sesión en su cuenta, deberá usar su contraseña y un código de verificación único que fue generado por la aplicación Google Authenticator en su teléfono inteligente.",
			"no_auth_td": "Deshabilitar la autenticación de dos factores",
			"proteja_2fa": "Proteja su cuenta con la verificación en dos pasos",
			"proteja_2fa_sub": "Siempre que inicie sesión en su cuenta, deberá utilizar su contraseña y un código de verificación.",
			"addcamada_td": "Agrega una capa adicional de seguridad",
			"addcamada_sub": "Ingrese su contraseña y el código de verificación único que generó la aplicación Google Authenticator en su teléfono inteligente.",
			"keep_away_td": "Mantén alejados a los malos",
			"keep_away_sub": "Incluso si alguien obtiene su contraseña, no será suficiente para iniciar sesión en su cuenta.",
			"do_auth_td": "Habilitar la autenticación de dos factores",
			"siga_passos_td": "Siga estos pasos para habilitar la autenticación de dos factores",
			"step_1": "Abra la aplicación Google Authenticator en su teléfono inteligente.",
			"step_2": "Clique no botão de adicionar e depois clique em Ler código QR.",
			"step_3": "Apunte la cámara de su teléfono inteligente al código siguiente:",
			"dig_td": "Introduzca el código de verificación",
			"code_verific_td": "Código de verificación",
			"code_verific_sub": "Ingrese el código que aparece en su teléfono inteligente. Si se acaba el tiempo, simplemente ingrese el nuevo código que apareció.",
			"doverify_td": "Cheque",
			"invalid_td": "Código de verificación invalido",
			"load_qr": "Cargando código QR ...",
			"dig_login_td": "Ingrese el código de verificación generado en la aplicación Google Authenticator",
			"materiais_td": "Materiales de apoyo",
			"msg_sucesso": "Su factura se generó correctamente y se reenreenviará a su correo electrónico de registro",
			"msg_renovacao": "Has alcanzado tu límite de victorias, renueva tu paquete.",
			"msg_btc": "Billetera no válida",
			"info_endereco": "Rellene todos los campos abajo",
			"plano_titulo": "Plano de carrera",
			"msg_carreira": "Su nuevo plan de carrera se ha actualizado",
			"completo_percent": "Completo",
			"pacotes_tb": "Lista de paquetes",
			"view_qtd": "Cantidad",
			"msg_pacote": "Paquete elegido con éxito",
			"msg_pacote_erro": "No se pudo finalizar",
			"msg_pacote_vazio": "Usted necesita elegir un paquete",
			"escolha_pacotes": "Seleccione un paquete",
			"usuario_td": "usuario",
			"erro_logar": "log de error",
			"fk_matriz": "Matriz",
			"erro_logar_msg": "nombre de usuario o contraseña no válido",
			"senha_td": "contraseña",
			"esqueci_td": "olvidó la contraseña",
			"acesso_td": "Access",
			"de_td": "de",
			"seg_td": "seguridad",
			"confirmar_td": "confirmar",
			"email_td": "correo electrónico",
			"enviar_td": "Enviar",
			"reenviar_td": "Reenviar",
			"visualizar_td": "Mostrar",
			"dias_td": "Días",
			"horas_td": "Horas",
			"minutos_td": "Minutos",
			"pais_td": "País",
			"paises_td": "Países",
			"estado_td": "Estado",
			"cidade_td": "Ciudad",
			"banco_de_dados": "Base",
			"meus_contatos": "Mis contactos",
			"banco_empresa": "Base de la Empresa",
			"escolher_td": "Elegir",
			"todos_td": "Todos",
			"segunda_td": "Lunes",
			"terca_td": "Martes",
			"quarta_td": "Miércoles",
			"quinta_td": "Jueves",
			"sexta_td": "Viernes",
			"sabado_td": "Sábado",
			"domingo_td": "Domingo",
			"seg_sex": "De lunes a viernes",
			"sab_dom": "Sábados y domingos",
			"manha_td": "Mañana",
			"tarde_td": "Tarde",
			"noite_td": "Noche",
			"ativar_conta": "Ingrese su cuenta de correo electrónico y confirme su registro",
			"ativar_zap": "Enviamos un mensaje al WHATSAPP:",
			"favor_zap": "Valide su WhatsApp haciendo clic en el enlace de activación en el mensaje que le enviamos.",
			"sim_td": "sí",
			"nao_td": "no",
			"parabens_td": "felicitaciones",
			"sucesso_td": "éxito",
			"erro_td": "error",
			"obrigado_td": "gracias",
			"cadastrar_td": "Register",
			"gravar_td": "Registro",
			"salvar_td": "Guardar",
			"agora_td": "ahora",
			"postador_td": "Publicar",
			"automatico_td": "Automático",
			"texto_td": "Texto",
			"imagem_td": "Imagen",
			"ddi_pais": "Código de país",
			"cancelar_td": "Cancelar",
			"continuar_td": "continuar",
			"parar_td": "STOP",
			"zap_td": "WhatsApp",
			"celular_td": "móvil",
			"telefone_td": "teléfono",
			"nome_td": "nombre",
			"endereco_td": "dirección",
			"logradouro_td": "dirección de la calle",
			"numero_td": "número",
			"numeros_td": "Números",
			"bairro_td": "barrio",
			"comp_td": "Complemento",
			"uf_td": "UF",
			"cep_td": "zip",
			"entrega_td": "Entrega",
			"data_td": "fecha",
			"nasc_td": "nacimiento",
			"plano_td": "Plan",
			"bem_vindo": "Bienvenido",
			"efetuado_td": "hecho con",
			"status_td": "status",
			"upgrade_td": "actualizar",
			"financeiro_td": "finanzas",
			"principal_td": "Main",
			"banco_td": "Bancos",
			"bancarios_td": "Banca",
			"rede_td": "red",
			"binario_td": "binario",
			"direto_td": "Directa",
			"linear_td": "lineal",
			"matriz_td": "array",
			"trans_td": "Transfer",
			"saldo_td": "balance",
			"pg_saldo": "Pagar con saldo",
			"liberado_td": "liberado",
			"bloqueado_td": "bloqueado",
			"pendente_td": "pendiente",
			"dados_td": "datos",
			"pessoais_td": "Personal",
			"nivel_td": "nivel",
			"buscar_td": "Get",
			"moeda_td": "moneda",
			"dinheiro_td": "dinero",
			"carreira_td": "carrera",
			"subir_td": "para arriba",
			"foto_td": "foto",
			"msg_td": "mensaje",
			"aqui_td": "aquí",
			"extrato_td": "extraer",
			"sair_td": "Exit",
			"logar_td": "Inicia sesión",
			"loja_td": "Tienda",
			"alterar_td": "reemplazar",
			"perna_td": "pierna",
			"direita_td": "derecha",
			"esquerda_td": "Izquierda",
			"pedido_td": "Petición",
			"btc_td": "Bitcoin",
			"sacar_td": "Dibujar",
			"taxa_td": "Tasa",
			"estorno_td": "Revocación",
			"link_de": "Enlace de registro",
			"patrocinador_td": "sponsor",
			"qualif_td": "calificado",
			"qualificacao_td": "Calificación",
			"renovar_td": "renovar",
			"ativo_td": "Activo",
			"inativo_td": "Inactivo",
			"comprar_td": "comprar",
			"comprar_prod": "Comprar productos",
			"titulo_msg": "atención",
			"erro_msg": "no se puede generar la solicitud",
			"titulo_sucesso": "felicitaciones",
			"sucesso_msg": "solicitud generada con éxito",
			"pagar_td": "pay",
			"realmente_td": "realmente quiero",
			"valor_exato": "Pagar el valor anterior exactamente igual",
			"com_td": "con",
			"do_td": "de",
			"virgula_td": ",",
			"apagar_td": "Eliminar",
			"limite_td": "Límite",
			"entrada_td": "entrada",
			"saida_td": "Salida",
			"total_td": "total",
			"sub_total": "sub-total",
			"participe_td": "join",
			"cota_td": "cuota",
			"divisao_lucro": "división de lucro",
			"configura_td": "configuración",
			"aviso_td": "ADVERTENCIA",
			"carrinho_td": "carrito",
			"vazio_td": "Este campo no puede estar vacío",
			"abrir_td": "Abierto",
			"fechar_td": "Cerca",
			"cpf_td": "Documento",
			"menu_td": "Menú",
			"valor_td": "Valor",
			"pref_td": "Recomendado",
			"auto_td": "Más Pequeño",
			"escolha_td": "Elección",
			"disponivel_td": "Disponible",
			"pagamento_td": "Pago",
			"boleto_td": "Billete",
			"opcao_td": "Opciones",
			"pago_td": "Pagado",
			"desc_td": "Descripción",
			"cred_td": "Crédito",
			"deb_td": "Débito",
			"tipo_td": "Especie",
			"privado_td": "Privado",
			"grupo_td": "Grupo",
			"tipo_conta": "Tipo de cuenta",
			"agencia_td": "Agencia",
			"conta_td": "Cuenta",
			"dv_td": "Dígito",
			"favorecido_td": "Favorecido",
			"corrente_td": "Actual",
			"poupanca_td": "Ahorros",
			"update_td": "Actualización",
			"escreva_td": "Escribir",
			"reescreva_td": "Reescribir",
			"ate_td": "Hasta",
			"nova_td": "Nuevo",
			"atual_td": "Actual",
			"antiga_td": "Viejo",
			"carteira_td": "Cartera",
			"pix_td": "llave PIX",
			"saque_td": "Servir",
			"brinde_td": "Brindis",
			"ativar_td": "Activar",
			"upline_td": "Línea",
			"frete_td": "Carga",
			"passar_prazo_td": "Si pasas el plazo debes generar",
			"potencial_td": "Potencial de Ganancias",
			"ficticio_vl": "Valores ficticios",
			"ja_ganhei": "He ganado en",
			"campanha_td": "Campaña",
			"adicionar_td": "Añadir",
			"criar_td": "Crear",
			"base_empresa": "Lista de la compañía",
			"minha_base": "Mi lista",
			"ferramentas_td": "Herramientas",
			"licencas_td": "Licencias",
			"serial_td": "Chave",
			"videos_td": "Lección de video",
			"arquivo_td": "Archivo",
			"importar_td": "Importar",
			"exportar_td": "Exportación",
			"bonus_td": "Bono",
			"investidor_td": "Inversor",
			"simples_td": "Simple",
			"novamente_td": "Otra vez",
			"robo_td": "Robot",
			"segmentado_td": "Segmentado",
			"text_numeros": "Números o enlaces de grupos separados por ; Ejemplo: xxxxxx;xxxxxx;xxxxxx",
			"envia_100": "Envía 100 contactos de",
			"msg_moderacao": "¡Atención! Use el robot con moderación si tiene miedo de no usar su chip personal.",
			"modo_td": "Modo",
			"msg_cad": "Para continuar el registro escoja un pacto!",
			"emocao_td": "Every hour a new emotion!",
			"pack": "Mi paquete",
			"data_ativa": "Fecha de activación",
			"qualif_binario": "Calificación en binario",
			"plano_al": "Plano de carrera",
			"qtd_rede": "Cantidad de red",
			"sys_tb": "En el sistema",
			"saque_td2": "Retirar",
			"saque_td3": "Con éxito",
			"ate_hj": "Hasta hoy",
			"entrada_et": "Entrada",
			"tb_usuarios": "Usuarios",
			"tb_usando_sys": "En el sistema",
			"plano_main": "Cambiar plan",
			"plano_main2": "Quiero cambiar mi plan",
			"escolha_plano": "Elija un nuevo plan a continuación",
			"package_plano": "Paquete",
			"escolher_plano": "Elegir",
			"total_e": "Izquierda total",
			"total_d": "Derecho total",
			"usu_rede": "Inicio de sesión de usuario debajo de su red",
			"voltar_top": "Volver al principio",
			"sub_nivel": "Subir un nivel",
			"list_p": "Lista de puntos",
			"ped_b": "Pedido",
			"ped_bs": "Órdenes",
			"usu_b": "Usuario",
			"data_b": "Fecha",
			"pts_b": "Puntos",
			"log_usu": "Ingresar usuario",
			"total_ee": "Izquierda",
			"total_dd": "Derecha",
			"limit_b": "Límite",
			"senha_up": "Cambiar contraseña",
			"senha_f": "Cambiar contraseña financiera",
			"taxa": "Cuota",
			"comprovante_msg": "Enviar prueba",
			"selecione_tb": "Selecciona una foto",
			"chamado": "Abrir una llamada",
			"list_chamado": "Lista de llamadas",
			"n_chamado": "Nº",
			"res_data": "Respuesta",
			"ass_chamado": "Asunto",
			"respondido": "Respondió",
			"aberto": "Abrir",
			"depart": "Elige un departamento",
			"finan": "financiero",
			"support": "Soporte",
			"succ_call": "Llamada abierta exitosamente",
			"err_call": "No se pudo abrir un ticket",
			"succ_chamado": "Su llamada se envió con éxito",
			"err_chamado": "No se puede enviar su mensaje",
			"atencao_td": "Atencion",
			"senha_valid": "Contraseña no válida, verifique sus datos y vuelva a intentarlo!",
			"success_senhaf": "Contraseña confirmada con éxito!",
			"carteira_td_succss": "Wallet se registró con éxito, revisa su correo electrónico para activar!",
			"pix_td_succss": "¡Pix recordó con éxito, revisa su correo electrónico para activar!",
			"saque_email_td": "Dibujo solicitado, verifique su correo electrónico y haga clic en el enlace para autorizar!",
			"msg_wallet_email": "Para su mejor seguridad, datos para el pago de esta solicitud. ",
			"msg_wallet_email2": "han sido enviados al correo electrónico ",
			"msg_wallet_email3": "Echa un vistazo al número de pedido y la fecha límite para el pago reportada en el correo electrónico.",
			"carteira_td_erro": "Incapaz de registrar su carrera, verifique sus datos y vuelva a intentarlo.",
			"cadastre_carteira": "Registre una billetera BTC o una tecla PIX para realizar retiros",
			"msg_btc_vazio": "Rellene el campo Bitcoin",
			"sacar_api_td": "API de pago",
			"limite_80": "Ha alcanzado más del 80% de su límite y se ha generado automáticamente una solicitud de renovación. Pague ese pedido y evite bloquear sus Cashback.",

			"cred_ind_direta": "Crédito de referencia directo",
			"id_td": "Indicación directa",
			"ii_td": "Indicación Indirecta",
			"cred_ind_indireta": "BONO DE EQUIPO",
			"cred_mensalidade": "UNILEVEL",
			"debt_td": "Deuda",
			"cred_binario": "Crédito binario",
			"transf_debito": "Transferencia de débito",
			"transf_credito": "Transferencia de credito",
			"ped_retirada": "Solicitud de Retiro",
			"extorno_td": "Devolución de retiro",
			"tx_adm_deb": "Administrar impuestos",
			"tx_adm_cred": "Administrar impuestos",
			"cred_saldo_disp": "Saldo de crédito disponible",
			"deb_de_trader": "Deuda del comerciante",
			"ajust_limite": "Ajuste de umbral",
			"cred_pontos": "Puntos de crédito",
			"deb_saldo_disp": "Saldo de débito disponible",
			"pl_carreira": "Trayectoria profesional",
			"ganh_diario": "CASHBACK",
			"saq_duplicado": "Retiro duplicado",
			"ajust_saldo": "Ajuste de equilibrio",
			"tx_retirada": "Cargo por retiro",
			"cred_pagar_com_saldo": "Pago con crédito con saldo",
			"cred_upgrade": "Crédito UpGrade",
			"limite_excedido": "Límite de deuda excedido",
			"cred_renovacao": "Renovación de crédito",
			"deb_saldo": "Balance de débito",
			"doacao_td": "Donación",
			"doa_men_receb": "Donación mensual de plataforma recibida",
			"bonus_saldo_trader": "Bono de saldo de comerciante",
			"cred_recb_trader": "Operador receptor de crédito",
			"deb_val_indev": "Monto indebido de la deuda",
			"cred_game_donate": "Crédito para donar juegos",
			"deb_game_donate": "Juego de débito Donar",

			"ser_investidor": "Para ser un inversor en nuestra plataforma, debe ser referido por alguien.",
			"indicante_td": "¿Quién está diciendo?",
			"escreva_indicante": "Escribe el nombre de usuario de tu indicador",
			"erro_user_msg": "Error que encuentre al usuario!",
			"aceite_td": "Acepto",
			"terms_td": "términos y condiciones",
			"indicado_por": "Estás siendo indicado por:",
			"cadastrado_td": "¿Ya registrado? Haga clic aquí e inicie sesión!",
			"ainda_não_cadastrado": "¿Aún no está registrado?",
			"senha_nao_confere": "La contraseña y la confirmación deben ser iguales.",
			"senhaf_nao_confere": "La contraseña financiera y la confirmación deben ser iguales.",
			"login_ja_tem": "¡Este inicio de sesión ya existe! ¡Por favor elije otro!",
			"invalido_td": "inválido",
			"esqueci_td_success": "¡La contraseña cambió correctamente, revisa su correo electrónico!",
			"empty_td": "Vacío",
			"objetivo_td": "Objetivo",
			"info_pessoal": "Informaciones Personales",

			"semsaldo_td": "Saldo Insuficiente",
			"pacotemenor_td":"Solo puede sacar hasta el valor de su paquete por día",
			"body_mensalidade":"Su UNILEVEL ha caducado y se ha generado una nueva orden de pago, pague la orden y reciba sus Cashback nuevamente",
			"body_dia_primeiro":"Haz tu activación mensual y garantiza que tu tarjeta PIX MANIA esté activada y también tu bono UNINIVEL.",

			"complemento_td":"complemento",
			"nascimento_td":"Fecha de nacimiento",
			"documento_td":"Pasaporte",
			"documento_repetido":"Documento Registrado",
			"saque_minimo":"Saque Mínimo",
			"aleatoria_td":"Aleatorio",
			"forma_td":"Forma de recibo",
			"enviar_link":"Enviar video enlace",
			"url_video":"Ingrese la URL de video",
			"link_enviado":"Video Link enviado exitosamente!",
			"link_nao_enviado":"Enlace de video no enviado!",
			"utilizado_td":"Utilizado",
			"recusado_td":"Rechazado"
		}
	}
}

export { messages }