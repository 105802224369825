import React, { useState } from 'react'
import axios from 'axios'

import { isAuthenticated } from './Auth'
import { Redirect, Link } from "react-router-dom"
import { Form } from 'react-bootstrap'
import { i18n } from '../Components/Translates/i18n'

export default function Login () {
	document.title = `LOGIN | ${process.env.REACT_APP_NAME}`

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const doLogin = function () {
		let user = {
			username : username,
			password : password
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/Bo/login`, user).then(success => {
			if (success.data.status) {
				sessionStorage.setItem('token', success.data.dados.token)
				let local = window.location
				window.location = local
			} else {
				alert(i18n.t('erro_logar_msg'))
			}
		}).catch(error => {
			alert(i18n.t('erro_logar_msg'))
		})
	}

	return (
		<>
			{!isAuthenticated() ? 
				<div className="wrapper">
					<div className="section-authentication-login d-flex align-items-center justify-content-center">
						<div className="row">
							<div className="mx-auto col-12 col-lg-10">
								<div className="card radius-15">
									<div className="row no-gutters">
										<div className="col-lg-6">
											<div className="card-body p-md-5">
												<Link to="/">
													<div className="text-center">
														<img src="/logos/logo.png" height="80" alt="logo"/>
														<h3 className="mt-4 font-weight-bold">LOGIN</h3>
													</div>
												</Link>
												<hr />
												<Form.Group className="mt-4">
													<Form.Label>{i18n.t('usuario_td')}</Form.Label>
													<Form.Control type="text" placeholder={i18n.t('log_usu')} autoComplete="username" onChange={e => setUsername(e.target.value)} />
												</Form.Group>
												<Form.Group className="mt-4">
													<Form.Label>{i18n.t('senha_td')}</Form.Label>
													<Form.Control type="password" placeholder={i18n.t('senha_td')} autoComplete="password" onChange={e => setPassword(e.target.value)} />
												</Form.Group>
												<div className="form-row">
													<div className="text-right form-group col">
														<Link to="/backoffice/forget">
															<i className='mr-2 bx bxs-key'></i>{i18n.t('esqueci_td')}
														</Link>
													</div>
												</div>
												<div className="mt-3 btn-group w-100">
													<button type="button" className="btn btn-light btn-block" onClick={e => doLogin()}>{i18n.t('enviar_td')}</button>
													<button type="button" className="btn btn-light" onClick={e => doLogin()}><i className="lni lni-arrow-right"></i>
													</button>
												</div>
												<hr/>
												<div className="text-center">
													<p className="mb-0">{i18n.t('ainda_não_cadastrado')} 
													<Link to="/backoffice/register">{i18n.t('cadastrar_td')}</Link>
													</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<img src="/assets/images/login-images/login-frent-img.jpg" className="card-img login-img h-100" alt="imageLogin"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			: <Redirect to={{pathname:'/backoffice/bo'}} />}
		</>
	)
}