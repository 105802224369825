import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Container, Alert, Button, Col, Form, Row, InputGroup } from 'react-bootstrap'
import { i18n } from '../../Components/Translates/i18n'
import Modals from '../../Components/SubComponents/Modals'

export default function WithdrawalBtc() {
	document.title = `${i18n.t('sacar_td')} BTC | ${process.env.REACT_APP_NAME}`
	
	const token = sessionStorage.getItem('token')

	const [alertSuccess, setAlertSuccess] = useState(false)
	const [alertError, setAlertError] = useState(false)
	const [alertErrorMsg, setAlertErrorMsg] = useState('')
	const [alertErrorSenhaSaq, setAlertErrorSenhaSaq] = useState(false)
	const [alertLinkSuccess, setAlertLinkSuccess] = useState(false)

	const [carteira, setCarteira] = useState([])
	const [taxa, setTaxa] = useState(10)
	const [seg, setSeg] = useState('')
	const [valor, setValor] = useState(0)
	const [forma, setForma] = useState('')
	const [pix, setPix] = useState([])
	const [link, setLink] = useState('')
	const [links, setLinks] = useState([])

	const [modalShow, setModalShow] = useState(false)

	useEffect(() => {
		const getWalletView = () => {
			window.scrollTo(0, 0)
			axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/saque_bitcoin/token/${token}`).then(success => {
				setCarteira(success.data.carteira)
				setPix(success.data.meuspix)
				setLinks(success.data.links)
				if(!success.data.descontoaprovado){
					setTaxa(10)
				} else {
					setTaxa(5)
				}
			}).catch(error => {
				console.log(error)
				/* sessionStorage.removeItem('token')
				let local = window.location
				window.location = local */
			})
		}
		getWalletView()
	}, [token])

	const validPassword = () => {
		let user = {
			senha : seg
		}
		user = JSON.stringify(user)
		axios.post(`${process.env.REACT_APP_URL_API}/Usuario/valid_senhaf/token/${token}`, user).then(success => {
			if(!success.data.status){
				setSeg('')
				setAlertErrorSenhaSaq(true)
			} else {
				setAlertErrorSenhaSaq(false)
			}
		}).catch(error => {
			console.log(error)
			sessionStorage.removeItem('token')
			let local = window.location
			window.location = local
		})
	}

	const doSaqueBtc = () => {
		let post = {
			senha : seg,
			valor : valor,
			forma
		}
		post = JSON.stringify(post)
		axios.post(`${process.env.REACT_APP_URL_API}/ContaCorrente/saque_bitcoin/token/${token}`,post).then(success => {
			if(success.data.status){
				setAlertError(false)
				setAlertSuccess(true)
				setSeg('')
				setValor('')
				axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/saque_bitcoin/token/${token}`).then(success => {
					setCarteira(success.data.carteira)
					setPix(success.data.meuspix)
					setLinks(success.data.links)
					if(!success.data.descontoaprovado){
						setTaxa(10)
					} else {
						setTaxa(5)
					}
				}).catch(error => {
					console.log(error)
				})
			} else {
				setAlertSuccess(false)
				setAlertError(true)
				setAlertErrorMsg(i18n.t(success.data.msg))
			}
		}).catch(error => {
			console.log(error)
			/* sessionStorage.removeItem('token')
			let local = window.location
			window.location = local */
		})
	}

	const sendLink = () => {
		let post = {
			link
		}
		post = JSON.stringify(post)
		axios.post(`${process.env.REACT_APP_URL_API}/ContaCorrente/sendLink/token/${token}`,post).then(success => {
			if(success.data.status){
				setAlertLinkSuccess(true)
				setModalShow(false)
				axios.get(`${process.env.REACT_APP_URL_API}/ContaCorrente/saque_bitcoin/token/${token}`).then(success => {
					setCarteira(success.data.carteira)
					setPix(success.data.meuspix)
					setLinks(success.data.links)
					if(!success.data.descontoaprovado){
						setTaxa(10)
					} else {
						setTaxa(5)
					}
				}).catch(error => {
					console.log(error)
				})
			} else {
				setAlertLinkSuccess(false)
				console.log(success.data)
				alert(i18n.t('link_nao_enviado'))
			}
		}).catch(error => {
			console.log(error)
		})
	}

	return (
		<>
		{carteira.fk_status | ( pix[0] && pix[0].fk_status === 1 )?
			<>
				<Row>
					<Col>
						<div className="card radius-15">
							<div className="pb-0 border-0 card-header d-sm-flex d-block">
								<div className="pr-3 mb-3 mr-auto mb-sm-0">
									<h4>{i18n.t('sacar_td')} - {i18n.t('taxa')} {taxa}%</h4>
									<h6>{i18n.t('saque_minimo')} US$10,00</h6>
									<Button variant="light" onClick={() => setModalShow(true)}>{i18n.t('enviar_link')}</Button>
									{carteira.fk_status ? <p>{i18n.t('carteira_td')}: {i18n.t(carteira.carteira)}</p>:''}
								</div>
							</div>
							<div className="card-body">
								<Row>
									<Col>
										<Form>
											{/* <Alert variant="info"><strong>{i18n.t('atencao_td')}</strong> {i18n.t('msg_saque_dia_td')}</Alert> */}
											{alertLinkSuccess ?
												<Alert variant="success" onClose={() => setAlertLinkSuccess(false)} dismissible>{i18n.t('link_enviado')}</Alert>
											: ''}
											{alertSuccess ?
												<Alert variant="success" onClose={() => setAlertSuccess(false)} dismissible>{i18n.t('saque_email_td')}</Alert>
											: ''}
											{alertError ?
												<Alert variant="danger" onClose={() => setAlertError(false)} dismissible> <strong>{i18n.t('erro_td')}</strong> {alertErrorMsg}</Alert>
											: ''}
											{alertErrorSenhaSaq ?
												<Alert variant="danger" onClose={() => setAlertErrorSenhaSaq(false)} dismissible> <strong>{i18n.t('atencao_td')}</strong> {i18n.t('senha_valid')}</Alert>
											: ''}
											<Row>
												<Col md="3">
													<Form.Group>
														<Form.Label>{i18n.t('senha_td')} {i18n.t('financeiro_td')}</Form.Label>
														<Form.Control type="password" placeholder={`${i18n.t('senha_td')} ${i18n.t('financeiro_td')}`} value={seg} autoComplete="off" onChange={e => setSeg(e.target.value)} onBlur={() => validPassword()}/>
													</Form.Group>
												</Col>
												<Col md="3">
													<Form.Group>
														<Form.Label>{i18n.t('valor_td')}</Form.Label>
														<Form.Control type="number" placeholder={i18n.t('valor_td')} value={valor} autoComplete="off" onChange={e => setValor(e.target.value)} />
													</Form.Group>
												</Col>
												<Col md="3">
													<Form.Group>
														<Form.Label>{i18n.t('forma_td')}</Form.Label>
														<Form.Control as="select" value={forma} autoComplete="off" onChange={e => setForma(e.target.value)}>
															<option value="">{i18n.t('escolha_td')}</option>
															{carteira.fk_status?
																<option value="btc">Bitcoin</option>
															:''}
															{pix.map((el, index) =>
															<React.Fragment key={index}>
																{el.fk_status === 1?
																<option value={el.id}>PIX tipo {el.tipo}: {el.chave}</option>
																:''}
															</React.Fragment>
															)}
														</Form.Control>
													</Form.Group>
												</Col>
												<Col md="3">
													<Form.Group>
														<Form.Label>{i18n.t('sacar_td')}</Form.Label><br/>
														<Button variant="success" disabled={!seg | !valor } onClick={() => doSaqueBtc()}>{i18n.t('sacar_td')}</Button>
													</Form.Group>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
				{links.length > 0?
					<Row>
						<Col>
							<div className="card radius-15">
								<div className="pb-0 border-0 card-header d-sm-flex d-block">
									<div className="pr-3 mb-3 mr-auto mb-sm-0">
										<h4>Links</h4>
									</div>
								</div>
								<div className="card-body">
									<Row>
										{links.map((el, index) => 
											<Col md="3" key={index}>
												<div className="card">
													<div className="list-group">
														{
															el.status === 0 ?
															<div className="list-group-item bg-warning text-dark d-flex justify-content-between"><span>{i18n.t('pendente_td').toUpperCase()}</span></div>

															:el.status === 1 ?
															<div className="list-group-item bg-success d-flex justify-content-between"><span>{i18n.t('liberado_td').toUpperCase()}</span></div>

															:el.status === 2 ?
															<div className="list-group-item bg-secondary d-flex justify-content-between"><span>{i18n.t('utilizado_td').toUpperCase()}</span></div>

															:el.status === 3 ?
															<div className="list-group-item bg-danger d-flex justify-content-between"><span>{i18n.t('recusado_td').toUpperCase()}</span></div>

															:''
														}
														<a href={el.url} target="_blank" rel="noopener noreferrer" className="list-group-item">{el.url}</a>
													</div>
												</div>
											</Col>
										)}
									</Row>
								</div>
							</div>
						</Col>
					</Row>
				:''}
			</>
		:
			<Row>
				<Col>
					<Alert variant="warning">
						<Row>
							<Col md="8">
								<h4 className="text-dark"><strong>{i18n.t('cadastre_carteira')}</strong></h4>
							</Col>
							<Col md="2">
								<Link to="/backoffice/updatewallet">
									<Button variant="warning btn-block">
										{i18n.t('cadastrar_td')} {i18n.t('carteira_td')}
									</Button>
								</Link>
							</Col>
							<Col md="2">
								<Link to="/backoffice/updatepix">
									<Button variant="warning btn-block">
										{i18n.t('cadastrar_td')} {i18n.t('pix_td')}
									</Button>
								</Link>
							</Col>
						</Row>
					</Alert>
				</Col>
			</Row>
		}
		<Modals
			size="lg"
			title={i18n.t('enviar_link')}
			show={modalShow}
			onHide={() => setModalShow(false)}
		>
			<InputGroup>
				<Form.Control type="text" placeholder={i18n.t('url_video')} value={link} autoComplete="off" onChange={e => setLink(e.target.value)} />
				<InputGroup.Append>
					<Button variant="primary" disabled={!link } onClick={() => sendLink()}>{i18n.t('enviar_td')}</Button>
				</InputGroup.Append>
			</InputGroup>
		</Modals>
		</>
	)
}